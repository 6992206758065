import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";

// Compatibility with passenger cars
const CompatibilityCars = ({ ART_ID, invalidPartMatch }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const { gdcURL, PCID, setCategories, categories } = useContext(ConfigContext);
    const dir = localStorage.getItem('dir'); 
    const [fitins, setFitIns] = useState({});
    const [loadingFitIn, setLoadingFitIn] = useState(true);
    const [car_info, setCarInfo] = useState(localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): []);
    const [showAll, setShowAll] = useState(false);
    useEffect(() => {
        let make = '';
        if (localStorage.getItem('car_info')) {
            const car_info = JSON.parse(localStorage.getItem('car_info'));
            make = car_info[0].MFA_BRAND;
        }
        
        setLoadingFitIn(true);
        fetch(`${gdcURL}/Parts/GetPartFitIn?lang=${locale}&art_id=${ART_ID}&make=${make}`)
            .then((res) => res.json())
            .then((data) => {
                console.log("Part Fit: ",data)
                var isInvalid = true
                if(localStorage.getItem('pc_id') && localStorage.getItem('pc_id') !='0'){
                    for(var ii = 0; ii < data.data.length; ii++){
                    
                        if(data.data[ii].PC_ID === Number(localStorage.getItem('pc_id'))){
                            isInvalid = false;
                        }
                        // alert(data.data[ii].PC_ID + "===" + Number(localStorage.getItem('pc_id')) )
                    }

                }
                
                invalidPartMatch(isInvalid);
                const groupedData = data.data.reduce((acc, item) => {
                    const brand = item.MFA_BRAND;
                    if (!acc[brand]) {
                        acc[brand] = [];
                    }
                    var from = item.PCS_CI_FROM?item.PCS_CI_FROM.split('-')[0]:  '';
                    var to = item.PCS_CI_TO?item.PCS_CI_TO.split('-')[0]:  '2024';

                    acc[brand].push({typel: item.TYPEL, term: item.TERM_OF_USE, from: from, to: to});
                    return acc;
                }, {});
                setFitIns(groupedData);
               
                setLoadingFitIn(false);
            })
            .catch((error) => {
                console.error('Error fetching fit ins:', error);
                setLoadingFitIn(false);
            });
    }, [ART_ID, gdcURL, locale]);

    return (
        <div className="container">
            <h4 style={{lineHeight: 0}}>{t('compatibility_with_passenger_cars')}</h4>
            <div style={{paddingBottom: '20px', color: '#009e7e'}}><i className="fas fa-check-circle"></i> {car_info && car_info[0] && car_info[0].TYPEL}</div>
            <div id="accordion1">
                <div className="accordion" id="carAccordion">
                    {loadingFitIn ? (
                        <div>Loading...</div>
                    ) : (
                        <>
                            {Object.entries(fitins).slice(0, showAll ? fitins.length : 3).map(([brand, models], index) => (
                                <div className="card card-accordion accordion-boxed" key={index}>
                                    <a
                                        className="card-header collapsed"
                                        href="#"
                                        data-toggle="collapse"
                                        data-target={`#collapse${index}`}
                                        aria-expanded="false"
                                        aria-controls={`collapse${index}`}
                                    >
                                        {brand}
                                    </a>
                                    <div
                                        id={`collapse${index}`}
                                        className="collapse"
                                        data-parent="#carAccordion"
                                    >
                                        <div className="p-5">
                                            <ul style={{listStyle: 'unset'}}>
                                                {models.map((model, modelIndex) => (
                                                    <li key={modelIndex}>{model.typel} {model.from} - {model.to} {model.term &&  <span>- ({t('term_of_use', {term: model.term})})</span>}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!showAll && fitins && Object.entries(fitins).length > 3 && (
                                <div className="mt-2" style={{ textAlign: 'center' }}>
                                    <NavLink onClick={() => setShowAll(true)} style={{ fontWeight: 'bold' }}>{t('show_more')}</NavLink>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>

           
        </div>
    );
};

export default CompatibilityCars;