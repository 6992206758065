import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import slugify from 'slugify';

import Carousel from 'react-multi-carousel';
import axios from 'axios';
import { Spinner } from '../../../Components/Shimmers';

import Sections from './Sections';
import { ConfigContext } from '../../../Context/ConfigContext';
import CatelogModal from './CatelogModal';

import Tabs from './Tabs';
import useIsMobile from '../../../Helper/useIsMobile';
import MobSection from './MobSection';
import { Helmet } from 'react-helmet';
import CarSeachBoxHorizontal from '../../Cars/CarSeachBoxHorizontal';
const Catelog  = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID, setCategories, categories, treeCategories} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    
    const [p_subs, setPsubs] = useState(null);
    const [p_index, setPindex] = useState(0);
    const [p_title, setPTitle] = useState(null);
    const [p_STR_ID, setPSTRID] = useState(null);
    // const [categories, setCategories] = useState([]);
    const [car, setCar] = useState([]);
    const [loading, setLoading] = useState(true);
    const {s_str_id} = useParams();
    const [stridtree, setSTRIDTree] = useState(s_str_id?[Number(s_str_id),null]:[null]);
   
    const [responsive, setResponsive] =  useState({
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
        slidesToSlide: 3 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 3,
        slidesToSlide: 2 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      }
    })
    useEffect(() => {
      
      window.scrollTo(0, 0);
      if(localStorage.getItem('pc_id') && localStorage.getItem('pc_id')!=='' && localStorage.getItem('pc_id')!=='0')
      {
        // alert(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
        setLoading(true);
        axios.get(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
        .then(data => {
          
          setCategories(JSON.parse(JSON.stringify(data.data.data))); 
          setLoading(false);
        })
      }else{
        setLoading(true);
        axios.get(`${gdcURL}/Categories/GetCategories?lang=${locale}`)
        .then(data => {
          
          setCategories(JSON.parse(JSON.stringify(data.data.data))); 
          setLoading(false);
        })
      }
         
      }, [PCID]);
    
    
    const RemoveCategorySTRID = () => {
        if( stridtree[0] !== null){
            setSTRIDTree(stridtree.slice(1));
        }
    }
   
    const car_info = localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): null;
    
   

      const [showModal, setShowModal] = useState(false);

      const handleShow = (subs, title, STR_ID, index) => {
          setPTitle(title);
          setPsubs(subs);
          setPSTRID(STR_ID);

          setPindex(Math.floor((( index )/ 6)));
      }

      const [selectedCategories, setSelectedCategories] = useState([]);
      const [selectedCategoryName, setSelectedCategoryName] = useState("...");
      const [selectedCategoryID, setSelectedCategoryID] = useState(0);
      const isMobile = useIsMobile();
    return (
        <main className="main">   
        <Helmet>
              <title>Spare Parts Catelog</title>
              
          </Helmet>
        <CarSeachBoxHorizontal />
        
          <div className="parts p-5">
            <div className="container" >
            {/* {loading === true && <div><center className='mt-5 pt-5'><Spinner/>Please wait...</center></div>} */}
              {isMobile && <MobSection/>}
              
              {loading === false && car_info && <p style={{fontSize: 'x-large', fontAlign: 'center'}}>{t('top_parts_categories_for', {value: car_info[0].TYPEL})}</p>}
              
              { loading === false && isMobile===false && car_info && categories &&  categories.length > 0 && treeCategories && treeCategories.length > 0 && <div  className={`container ${treeCategories.length}`}>
                <Carousel 
                        swipeable={false}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        
                        infinite={false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px">
                          {
                             treeCategories && treeCategories.map((item, indexxx) => {
                             
                              return  item.children && <div >
                                <div className='p-5 mr-4'style={{backgroundColor: '#f9fafb', height: '500px', overflow: 'hidden'}} >
                                  <div style={{minHeight: '100px'}}>
                                  <img
                                      src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                                      className="img-fluid"
                                      height={100}
                                      alt={item.STR_NODE_NAME}
                                      onClick={() => handleShow(item.children, item.STR_NODE_NAME, item.STR_ID, indexxx)} 
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                                        currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                        currentTarget.height = 100
                                      }} />
                                  </div>
                                    <hr style={{lineHeight: 0, margin: 0}}/>
                                    <center><div className='mt-2' style={{fontSize: 'large', color: '#000', height: '50px'}}>{item.STR_NODE_NAME}</div></center>
                                    <br />
                                    <ul style={{lineHeight: '25px'}}>
                                    {item.children && item.children.slice(0,5).map((child)=>(
                                      <li><NavLink to={`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`}>{child.STR_NODE_NAME}</NavLink></li>
                                    ))}
                                    </ul>
                                </div>
                              </div>
                            })
                          }
                        </Carousel></div>
              }
              
              {loading === false && isMobile===false && car_info && <div className='mt-4'><p style={{fontSize: 'x-large', fontAlign: 'center'}}>{t('please_choose_proper_group', {value: car_info[0].TYPEL})}</p></div>}
             
              <div className="flex-wrap d-flex justify-content-between align-items-center">
                {
                    stridtree[0] !== null?<h4 onClick={() => RemoveCategorySTRID()}> <i className="fas fa-arrow-left mr-3 my-icon"></i>{" "} </h4>: <div></div>
                }
                
              </div>
              { loading === false && isMobile===false && categories &&  categories.length > 0 && 
              
              <section className="feature-boxes-container" style={{background: '#f7f7f7'}}>
   
                <div className="container" data-animation-name="fadeInUpShorter" >
                <Tabs p_index={p_index} p_subs={p_subs}  p_title={p_title}  p_STR_ID={p_STR_ID}/>
                
                </div>
              </section>
              }
            </div>
          </div>
        <CatelogModal subs={selectedCategories} title={selectedCategoryName} STR_ID={selectedCategoryID} modalStatus={showModal} />
        </main>
      
    )
 
}

export default Catelog;