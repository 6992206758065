import React, {Component} from "react";
import { NavLink, Navigate } from "react-router-dom";
import { GetCarts } from "../../Helper/Carts";
import { ConfigContext } from "../../Context/ConfigContext";
import withRouter from "../../Routes/withRouter";
import { withTranslation } from 'react-i18next';
class AccountSiderBar extends Component{
  static contextType = ConfigContext;
    constructor(props){
        super(props);
    }
    LogoutHandle = (e) => {
      localStorage.removeItem("token");
      localStorage.removeItem("email_address");
      localStorage.removeItem("customer_id");
      localStorage.removeItem("mobile_number");
      localStorage.removeItem("first_name");
      localStorage.removeItem("mobile_number");
      localStorage.removeItem('carts');
      const {UpdateCartList, ChangeCartProcessStatus} = this.context;
      UpdateCartList(GetCarts());
      ChangeCartProcessStatus(true);
      this.props.navigate('/');

  }
    render() {
      const { t } = this.props;
      const dir = localStorage.getItem('dir');
        return (
            <div className="sidebar widget widget-dashboard mb-lg-0 mb-3 col-lg-3 order-0"  dir={dir}>
                  <h2 className="text-uppercase">{t("my_account")}</h2>
                  <ul
                    className="nav nav-tabs list flex-column mb-0"
                    role="tablist" 
                    dir={dir}
                  >
                    <li className="nav-item">
                    <NavLink
                        className="nav-link"
                        to={`/MyAccount`}
                      >
                        {t("dashboard")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to={`/MyAccount/Orders`}
                      >
                        {t("orders")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink
                        className="nav-link"
                        to={`/MyAccount/Downloads`}
                      >
                        {t("downloads")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink
                        className="nav-link"
                        to={`/MyAccount/Addresses`}
                      >
                        {t("addresses")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink
                        className="nav-link"
                        to={`/MyAccount/AccountDetails`}
                      >
                        {t("account_details")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink
                        className="nav-link"
                        to={`/Wishlist`}
                      >
                        {t("wishlist")}
                      </NavLink>
                    </li>
                    <li className="nav-item">
                    <button
                        className="btn btn-outline-dark btn-md" style={{width: '100%', marginTop: '20px'}}
                        onClick={this.LogoutHandle}
                        
                      >
                        {t("logout")}
                      </button>
                    </li>
                  </ul>
                </div>
        );
    }
}
export default withRouter(withTranslation()(AccountSiderBar));