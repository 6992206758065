import React, {Component} from "react";
import { NavLink } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
class Promotion extends Component{
  static contextType = ConfigContext;
    render(){
    return(
      <section
      className="promo-section bg-dark"
      data-parallax="{'speed': 2, 'enableOnMobile': true}"
      data-image-src=""
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div
        className="parallax-background"
        style={{
          backgroundImage: 'url("")',
          backgroundSize: "cover",
          backgroundPosition: "50% 0%",
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          height: "200%",
          transform: "translate3d(0px, -755.914px, 0px)",
        }}
      />
      <div className="promo-banner banner container text-uppercase">
        <div className="banner-content row align-items-center text-center">
          <div
            className="col-md-4 ml-xl-auto text-md-right"
            data-animation-name="fadeInRightShorter"
            data-animation-delay={600}
          >
            <h2 className="mb-md-0 text-white">
              {('TopCar')}
              <br />
              {('Parts')}
            </h2>
          </div>
          <div
            className="col-md-4 col-xl-3 pb-4 pb-md-0"
            data-animation-name="fadeIn"
            data-animation-delay={300}
          >
            <NavLink to={() => {}} className="btn btn-dark btn-black ls">
              {('ViewSale')}
            </NavLink>
          </div>
          <div
            className="col-md-4 mr-xl-auto text-md-left"
            data-animation-name="fadeInLeftShorter"
            data-animation-delay={600}
          >
            <h4 className="mb-1 mt-1 font1 coupon-sale-text p-0 d-block ls-n text-transform-none">
              <b>{('ExclusiveCoupon')}</b>
            </h4>
            <h5 className="mb-1 coupon-sale-text text-white ls p-0">
              <i className="ls-0">{('UPTO')}</i>
              <b className="text-white bg-secondary ls-n">{('AED100')}</b> {('OFF')}
            </h5>
          </div>
        </div>
      </div>
    </section>
    )
  }
}

export default Promotion