import React, {Component} from "react";
import {NavLink} from 'react-router-dom';
import { ConfigContext } from "../../Context/ConfigContext";
import {  Spinner } from "../../Components/Shimmers";
import axios from "axios";
class FooterOffers extends Component{
  static contextType = ConfigContext;
  constructor(props){
    super(props);
    this.state = {
        offers: [],
        loadingOffers: true,
    }
  }
  GetOffers = async () => {
    const { apiURL,  } = this.context;
    var lang = localStorage.getItem('lang')??'en';
    var currency = localStorage.getItem('currency')??'aed';
    try {
      const response = await axios.get(
        `${apiURL}/Offers/GetFooterOffers?country_id=225&lang=${lang}&currency=${currency}`
      );
      if (response.data.success) {
        this.setState({offers: response.data.data, loadingOffers: false});
        
      }else{
        this.setState({loadingOffers: false});
      }
    } catch (error) {
        this.setState({loadingOffers: false});
      // console.error("Error fetching part details:", error);
    }
  };
  componentDidMount(){
    this.setState({loadingOffers: true}, (prevState)=>{
        this.GetOffers();
    })
  }
    render(){
        return (
            <div className="product-widgets-container row pb-2">
                {
                    this.state.loadingOffers === true?<div className="col-lg-12 justify-content-center"> <center><Spinner /></center></div>:
                    (
                        this.state.offers.map((offer, index) => {
                            return <div key={`offer-tab-${index}`} className="col-lg-3 col-sm-6 pb-5 pb-md-0" data-animation-name="fadeInLeftShorter" data-animation-delay={200} >
                                <h4 className="section-sub-title">{offer.offer_title}</h4>
                                {
                                    offer.childrens.map((item, index)=>{
                                    
                                        return <NavLink key={`offer-sub-tab-${index}-${item.part_number}`} to={`/Part/${item.part_slug}`}><div className="product-default left-details product-widget">
                                            <figure>
                                                <img src={item.part_picture} width={84} height={84} alt={`${item.part_title} - ${item.part_number}`} />
                                            </figure>
                                            <div className="product-details">
                                                <h3 className="product-title"> {item.part_title}</h3>
                                                <div className="ratings-container">
                                                    <div className="product-ratings">
                                                    <span className="ratings" style={{ width: "100%" }} />
                                                    {/* End .ratings */}
                                                    <span className="tooltiptext tooltip-top" />
                                                    </div>
                                                    {/* End .product-ratings */}
                                                </div>
                                                {/* End .product-container */}
                                                <div className="price-box">
                                                    <span className="product-price">{item.display_price}</span>
                                                </div>
                                            </div>
                                        </div>
                                        </NavLink>
                                    })
                                }
                            </div>
                        })
                        
                    )
                }
            </div>
        );
    }
}

export default FooterOffers