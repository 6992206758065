import React, { useContext, useTransition } from "react";
import { NavLink } from 'react-router-dom';
import { ConfigContext } from "../../../Context/ConfigContext";
import {    useTranslation } from 'react-i18next';

const InfoBox = () => {
  const { t } = useTranslation();
  const { apiURL } = useContext(ConfigContext);

  return (
    <div className="container mt-3">
      <div
        className="info-boxes-slider owl-carousel owl-theme mb-2 owl-loaded owl-drag"
        data-owl-options="{
                'dots': false,
                'loop': false,
                'responsive': {
                    '576': {
                        'items': 2
                    },
                    '992': {
                        'items': 3
                    }
                }
            }"
      >
        <div className="owl-stage-outer"> <div className="owl-stage" style={{ transform: "translate3d(0px, 0px, 0px)", transition: "all 0s ease 0s", width: "1180px", }} >
            <NavLink to={`/Delivery`}>
            <div className="owl-item active" style={{ width: "393.333px" }}>
              <div className="info-box info-box-icon-left">
                <i className="icon-shipping" />
                <div className="info-box-content">
                  <h4>{t("free_shipping_return")}</h4>
                  <p className="text-body">{t("free_shipping_return.above")}</p>
                </div>
              </div>
            </div>
            </NavLink>
            <NavLink to={`/Cancelation`}>
            <div className="owl-item active" style={{ width: "393.333px" }}>
              <div className="info-box info-box-icon-left">
                <i className="icon-money" />
                <div className="info-box-content">
                  <h4>{t("money_back_guarantee")}</h4>
                  <p className="text-body">{t("money_back_guarantee.100")}</p>
                </div>
              </div>
            </div>
            </NavLink>
            <NavLink to={`/International-Customers`}>
            <div className="owl-item active" style={{ width: "393.333px" }}>
              <div className="info-box info-box-icon-left">
                <i className="icon-support" />
                <div className="info-box-content">
                  <h4>{t("online_sppprt_247")}</h4>
                  <p className="text-body">
                    {t("online_sppprt_247.everything_you_need_for_yourcar")}
                  </p>
                </div>
              </div>
            </div>
            </NavLink>
          </div>
        </div>
        <div className="owl-nav disabled">
          <button type="button" title="nav" role="presentation" className="owl-prev">
            <i className="icon-angle-left" />
          </button>
          <button type="button" title="nav" role="presentation" className="owl-next">
            <i className="icon-angle-right" />
          </button>
        </div>
        <div className="owl-dots disabled" />
      </div>

      {/* Banners section */}
      <div className="banners-container mb-2" style={{ display: 'none' }}>
        <div
          className="banners-slider owl-carousel owl-theme owl-loaded owl-drag"
          data-owl-options="{
                    'dots': false
                }"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 0s ease 0s",
                width: "1200px",
              }}
            >
              <div
                className="owl-item active"
                style={{ width: "380px", marginRight: "20px" }}
              >
                <div
                  className="banner banner1 banner-sm-vw d-flex align-items-center appear-animate animated fadeInLeftShorter appear-animation-visible"
                  style={{ backgroundColor: "rgb(204, 204, 204)", animationDuration: "1000ms" }}
                  data-animation-name="fadeInLeftShorter"
                  data-animation-delay={500}
                >
                  <figure className="w0">
                    <img
                      src={`${apiURL}/public/placeholder_category.jpeg`}
                      alt="banner"
                      width={380}
                      height={175}
                    />
                  </figure>
                  <div className="banner-layer">
                    <h3 className="m-b-2">Parts Festival</h3>
                    <h4 className="m-b-3 text-primary">30%<sup>OFF</sup></h4>
                    <NavLink to={`/Catalog`} className="btn btn-sm btn-dark">
                      ShopNow
                    </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="owl-item active"
                style={{ width: "380px", marginRight: "20px" }}
              >
                <div
                  className="banner banner3 banner-sm-vw d-flex align-items-center appear-animate animated fadeInRightShorter appear-animation-visible"
                  style={{ backgroundColor: "rgb(204, 204, 204)", animationDuration: "1000ms" }}
                  data-animation-name="fadeInRightShorter"
                  data-animation-delay={500}
                >
                  <figure className="w0">
                    <img
                      src={`${apiURL}/public/placeholder_category.jpeg`}
                      alt="banner"
                      width={380}
                      height={175}
                    />
                  </figure>
                  <div className="banner-layer text-right">
                    <h3 className="m-b-2">Upgrade your car</h3>
                    <h4 className="m-b-2 text-secondary text-uppercase">
                      Starting @AED05
                    </h4>
                    <NavLink to={() => {}} className="btn btn-sm btn-dark">
                      ShopNow
                    </NavLink>
                  </div>
                </div>
              </div>

              <div
                className="owl-item active"
                style={{ width: "380px", marginRight: "20px" }}
              >
                <div
                  className="banner banner3 banner-sm-vw d-flex align-items-center appear-animate animated fadeInRightShorter appear-animation-visible"
                  style={{ backgroundColor: "rgb(204, 204, 204)", animationDuration: "1000ms" }}
                  data-animation-name="fadeInRightShorter"
                  data-animation-delay={500}
                >
                  <figure className="w0">
                    <img
                      src={`${apiURL}/public/placeholder_category.jpeg`}
                      alt="banner"
                      width={380}
                      height={175}
                    />
                  </figure>
                  <div className="banner-layer text-right">
                    <h3 className="m-b-2">Filter Oil</h3>
                    <h4 className="m-b-2 text-secondary text-uppercase">
                      Starting @AED10
                    </h4>
                    <NavLink to={`/Results/Filter-Oil-137`} className="btn btn-sm btn-dark">
                      Shop Now
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-nav disabled">
            <button type="button" title="nav" role="presentation" className="owl-prev">
              <i className="icon-angle-left" />
            </button>
            <button type="button" title="nav" role="presentation" className="owl-next">
              <i className="icon-angle-right" />
            </button>
          </div>
          <div className="owl-dots disabled" />
        </div>
      </div>
    </div>
  );
};

export default InfoBox;
