import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../Context/ConfigContext";
import { useNavigate } from "react-router-dom";
import slugify from "slugify";

const TabsItems = ({subs, title, STR_ID, modalStatus}) => {
    const { t } = useTranslation();
    const {categories, gdcURL} = useContext(ConfigContext);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoryName, setSelectedCategoryName] = useState("...");
    const [selectedCategoryID, setSelectedCategoryID] = useState(0); 
    const dir = localStorage.getItem('dir');

    const navigate = useNavigate();
    useEffect(() => {
        setSelectedCategoryName(title);
        setSelectedCategories(subs);
        setSelectedCategoryID(STR_ID)
        setShowModal(modalStatus);
    }, [subs, title, STR_ID, modalStatus])  
    
    const GetSubMenu = ({parent, flag}) => {
        if(parent.children.length === 0){
          return <div className="row mb-3"> 
            <div className="col-md-12">
            <h5>{parent.STR_NODE_NAME}</h5>
            </div>
            <ItemTab item={parent} /> 
          </div>
        }
        return <div className="row mb-3">
          
          <div className="col-md-12">
          
            <h5  style={parent.children.length === 0 ? {color: 'blue', textDecoration: 'underline', cursor: 'pointer'}: {}} >{parent.STR_NODE_NAME}</h5>
          </div>
          {
            parent.children.length > 0 && parent.children.map((item) => {
              return <GetSubChilds childs={item} />
            })
          }
        </div>
      }
      const GetSubChilds = ({ childs }) => {
        
        if (childs.children && childs.children.length > 0) {
          
          return (
            <>
              {childs.children.map((item) => ( <ItemTab item={item} />
                
              ))}
            </>
          );
        } else {
          return ( <ItemTab item={childs} /> );
        }
      };
      const  removeDuplicates = (arr) => {
        const seen = new Set();
        return arr.filter(item => {
            const key = item.STR_NODE_NAME;
            return seen.has(key) ? false : seen.add(key);
        });
    }
      const ItemTab = ({item}) => {
        return <div dir={dir} className="col-md-3 submenutabforsection" onClick={() => navigate(`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`)}>
              <table>
                <tbody>
                  <tr>
                    <td width={60}>
                    <img
                        src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                        className="img-fluid"
                        alt={item.STR_NODE_NAME} 
                        style={{borderRadius: '5px'}}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null; 
                            currentTarget.src = "https://ghayar.ae/assets/images/prodefault.jpg";
                        }}
                        />
                    </td>
                    <td>{item.STR_NODE_NAME}</td>
                  </tr>
                </tbody>
              </table>
            </div>
      }

      return (
        <div className="container" >
            <div className="row">
            {
            removeDuplicates(selectedCategories).map((item) => {
                return <div className="col-md-12"><GetSubMenu flag={true} parent={item} /></div>
                
            })
            }
            </div>
        </div>
      )
}

export default TabsItems;