import React, {Component} from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import { Spinner } from "../../Components/Shimmers";
import { withTranslation } from 'react-i18next';
class WishList extends Component{
    
    static contextType = ConfigContext

    constructor(props) {
        super(props);
    
        this.state = {
          wishListItems: [],
          MySwal : withReactContent(Swal),
          loading: false,
        };
    
        this.customer_id = localStorage.getItem('customer_id');
      }
      componentDidMount() {
        this.setState({loading: true} ,(prevState) => {
          this.fetchWishList();
        })
        
      }
    
      fetchWishList = async () => {
        const { apiURL, token } = this.context;
        try {
          const headers = { 'token': token, 'Accept': 'application/json', 'Content-Type': 'application/json', };
    
          const response = await axios.get(`${apiURL}/Carts/GetWishlist?customer_id=${this.customer_id}`, { headers });
          const data = response.data;
         
          if (data.success) {
            this.setState({ wishListItems: data.data, loading: false });
          } else {
            this.setState({  loading: false });
            console.log(data.message);
          }
        } catch (error) {
          this.setState({  loading: false });
          console.log("Oops! Something went wrong", error);
        }
      }
      handleRemoveItem = async (customer_wishlist_id) => {
        const { apiURL, token } = this.context;
        try {
          const headers = {
            'token': token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          };
          
          const wishlistData = {
            customer_wishlist_id,
          };
    
          const result = await this.state.MySwal.fire({
            title: "Remove Item",
            text: "Are you sure you want to remove this item?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Remove",
            cancelButtonText: "Cancel",
            reverseButtons: true,
          });
    
          if (result.isConfirmed) {
            const response = await axios.pos(
              `${apiURL}/Carts/DeleteWishlistItem?customer_wishlist_id=${customer_wishlist_id}`,
              wishlistData,
              { headers }
            );
    
            const data = response.data;
    
            if (data.success) {
              this.fetchWishLis(); // Assuming fetchWishList is a method in your component
              toast.success("Item Removed successfully!", {
                position: toast.POSITION.BOTTOM_CENTER,
              });
            } else {
              console.log('Error: Something went wrong,', data.message);
            }
          } else {
            return;
          }
        } catch (error) {
          console.log('Error: Something went wrong', error);
        }
      }
    

    render(){
        const { wishListItems, loading } = this.state;
        const { t } = this.props;
        const dir = localStorage.getItem('dir');
        return(
            <main className="main">
            <div className="page-header">
              <div className="container d-flex flex-column align-items-center">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                  <div className="container">
                    <ol className="breadcrumb" dir={dir}>
                      <li className="breadcrumb-item"><NavLink to={'Wishlist'}>{t('home')}</NavLink></li>
                      <li className="breadcrumb-item active" aria-current="page">
                      {t('wishlist')}
                      </li>
                    </ol>
                  </div>
                </nav>
                <h1>{t('wishlist')}</h1>
              </div>
            </div>
            <div className="container">
              <div className="wishlist-title">
                <h3 className="p-2">{t('wishlist')} ({wishListItems.length}) </h3>
              </div>
              <div className="wishlist-table-container">
                <table className="table table-wishlist mb-0">
                  <thead>
                    <tr>
                      <th className="thumbnail-col" />
                      <th className="product-col">{t('product')}</th>
                      <th className="price-col">{t("price")}</th>
                      <th className="status-col">{t('status')}</th>
                      <th className="action-col">{t('actions')}</th>
                    </tr>
                  </thead>
                  {loading === true?
                  <tbody><tr><td className="text-center" style={{padding: '50px'}} colSpan={15}><center><Spinner /></center></td></tr></tbody>
                  :
                  <tbody>
                    {(wishListItems.length > 0 && wishListItems) ? (
                       wishListItems.map((item) => (
    
                       <tr className="product-row" key={item.customer_wishlist_id}>
                       <td>
                         <figure className="product-image-container">
                           <NavLink to={void(0)} className="product-image">
                             <img src={item.part_picture} alt="product" />
                           </NavLink>
                           <NavLink 
                             onClick={() => this.handleRemoveItem(item.customer_wishlist_id)}
                             className="btn-remove icon-cancel" title="Remove Product" 
                           />
                         </figure>
                       </td>
                       <td>
                         <h5 className="product-title">
                           <NavLink to={void(0)}>{item.part_title}</NavLink>
                         </h5>
                       </td>
                       <td className="price-box">{item.price}</td>
                       <td>
                         <span className={item.stock_qty>0?("badge badge-success"):("badge badge-danger")}  >{item.stock_qty>0?("InStock"):("Out of Stock")}</span>
                       </td>
                       <td className="action">
                        <NavLink to={`/Part/${item.part_slug}`} className="btn btn-dark btn-add-cart product-type-simple btn-shop">
                           {t("add_to_cart")}
                         </NavLink>
                         <NavLink to={`/Part/${item.part_slug}`} className="btn btn-quickview mt-1 mt-md-0" title="Quick View">
                          {t("quick_view")}</NavLink>
                       </td>
                       </tr>
                           ))
                           ) : (
                      <tr>
                      <td colSpan={5} className="text-center">
                        {/* <h5>{("No Wishlist Found")}</h5> */}
                        {t('no_wishlist')}
                      </td>
                    </tr>
                    )}

                  </tbody>}
                </table>
              </div>{/* End .cart-table-container */}
            </div>{/* End .container */}
          </main>
        )
    }
}

export default withTranslation()(WishList)