import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import slugify from "slugify";

const OtherParts = ({STR_ID}) => { 
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID, setCategories, categories} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir'); 
    const [STR_ID_PARENT, setPARENTSTRID] = useState(0);
    useEffect(() => {
        


        var obj = categories.find((item) => item.STR_ID === Number(STR_ID));
        try{
            if(obj.STR_ID_PARENT !== undefined){
                setPARENTSTRID(obj.STR_ID_PARENT);
                // alert(JSON.stringify(obj))
            }
        }catch(err){
            // alert(err)
        }
        
       
    }, [])
    const LoadRelatedCategories = (STRIDPARENT) => {
        var rows = [];
        for(var i = 0; i < categories.length; i++){
            if(categories[i].STR_ID_PARENT === STRIDPARENT){
                rows.push(categories[i]);
            }
        }
       
        const uniqueRows = rows.filter((row => {
            const seen = new Set();
            return row => seen.has(row.STR_NODE_NAME) ? false : seen.add(row.STR_NODE_NAME);
        })());
        return uniqueRows;
    }
    return (
        <div className="row">
            
            
                {
                    categories && categories.length > 0 && LoadRelatedCategories(STR_ID_PARENT).map((item) => {
                       
                            return <div key={item.STR_ID} className="col-md-2" onClick={() => { navigate(`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`); window.scrollTo(0, 0) } }>
                                <div className="card border-0 shadow-hover"  >
                                    <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                    <img
                                    src={`${gdcURL}/category-images/600x600/${item.STR_ID}`}
                                    className="img-fluid"
                                    alt={item.STR_NODE_NAME}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // Prevents infinite loop in case fallback also fails
                                      currentTarget.src = "https://placehold.co/100x100/FFFFFF/FFFFFF/png";
                                    }}
                                  />
                                    </div>
                                    <div className="card-text text-center pl-2 pr-2 mb-1">
                                        <h6>{item.STR_NODE_NAME}</h6>
                                    </div>
                                </div>
                            </div>
                            
                      
                    })
                }
              </div>
    )
}

export default OtherParts;