import React, { useState, useEffect, useContext } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink, useLocation } from "react-router-dom";
import AccountSiderBar from "./AccountSideBar";
import { AddressCard } from "../../Components/Cards/MyAccountComponents";
import { useTranslation } from 'react-i18next';

const Addresses = () => {
    const { apiURL, token } = useContext(ConfigContext);
    const [customersAddresses, setCustomersAddresses] = useState([]);
    const { t } = useTranslation();
    useEffect(() => {
        GetAddresses();
    }, []);
    const search = useLocation().search;
    const backUrl = new URLSearchParams(search).get('backUrl');
    const GetAddresses = async () => {
        try {
            const customer_id = localStorage.getItem("customer_id");
            const headers = { 
                token: token, 
                Accept: "application/json", 
                "Content-Type": "application/json" 
            };

            const response = await axios.get(
                `${apiURL}/Account/GetAddresses?customer_id=${customer_id}`,
                { headers }
            );

            const data = response.data;

            if (data.success) {
                setCustomersAddresses(data.data);
            } else {
                console.log("Error While Fetching Customer Addresses", data.message);
            }
        } catch (error) {
            console.log("Oops Something Went Wrong", error);
        }
    };

    const dir = localStorage.getItem('dir');

    return (
        <main>
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb" dir={dir}>
                                <li className="breadcrumb-item">
                                    <NavLink to="/">{t("home")}</NavLink>
                                </li>
                                <li className="breadcrumb-item">
                                    <NavLink to="/MyAccount">{t("my_account")}</NavLink>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t("addresses")}
                                </li>
                            </ol>
                        </div>
                    </nav>
                    <h1>{t("addresses")}</h1>
                </div>
            </div>
            <div className="container account-container custom-account-container">
                <div className="row">
                    <AccountSiderBar />
                    <div className={`col-lg-9 order-lg-last order-1 tab-content ${dir === 'rtl' ? 'text-right' : ''}`}>
                        <div className="tab-pane fade show active" id="address" role="tabpanel">
                            <div className="addresses-content">
                                <div className="address">
                                    <div className="card">
                                        <div className="card-body">
                                            <p className="card-text">
                                                {t("manage_address_line")}
                                            </p>
                                            <NavLink
                                                to={`/MyAccount/ManageAddress`}
                                                className="btn btn-default address-action link-to-tab"
                                                style={{ textDecoration: "none", background: '#cc000b', color: "white" }}
                                            >
                                                <i className="sicon-plus align-middle mr-3"></i> {t("add_new_address")}
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <div className="address">
                                    <div className="card">
                                        <div className="card-header">
                                            <i className="sicon-location-pin align-middle mr-3" /> {t("other_address")}
                                        </div>
                                        <div className="card-body">
                                            <div className="container-fluid">
                                                <div className="row">
                                                    {customersAddresses.map((item) => (
                                                        <div className="col-sm-4" key={`address-key-${item.address_id}`}>
                                                            <AddressCard backUrl={backUrl} onDataChanged={GetAddresses} item={item} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Addresses;
