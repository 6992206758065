import React, { useState, useEffect, useContext } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import AccountSiderBar from "./AccountSideBar";
import { useTranslation } from "react-i18next";

const AccountDetails = () => {
  const { apiURL, token } = useContext(ConfigContext); // Access context
  const [profileLoading, setProfileLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const {t} = useTranslation();
  const dir = localStorage.getItem('dir');
  useEffect(() => {
    window.scrollTo(0, 0);
    setProfileLoading(true);
    GetCustomerInfo();
  }, []); // Runs on component mount

  const GetCustomerInfo = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = { token, Accept: "application/json", "Content-Type": "application/json" };

      const response = await axios.get(
        `${apiURL}/Account/GetCustomerInfo?customer_id=${customer_id}`,
        { headers }
      );

      const data = response.data;
      if (data.success) {
        const info = data.data[0];
        setProfileLoading(false);
        setFirstName(info.first_name);
        setLastName(info.last_name);
        setEmailAddress(info.email_address);
        setPassword(info.password);
      } else {
        setProfileLoading(false);
        Swal.fire({
          title: "Error!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Okay"
        });
        console.log("Error While Fetching Customer Info", data.message);
      }
    } catch (error) {
      setProfileLoading(false);
      console.log("Oops Something Went Wrong", error);
    }
  };

  const UpdateProfile = async () => {
    try {
      const customer_id = localStorage.getItem("customer_id");
      const headers = { token, Accept: "application/json", "Content-Type": "application/json" };
      const formData = {
        customer_id,
        first_name: firstName,
        last_name: lastName,
        password,
        new_password: newPassword,
        confirm_password: confirmPassword
      };

      const response = await axios.post(
        `${apiURL}/Account/UpdateCustomerInfo`,
        formData,
        { headers }
      );

      const data = response.data;
      setUpdateLoading(false);
      if (data.success) {
        Swal.fire({
          title: "Success",
          text: data.message,
          icon: "success",
          confirmButtonText: "Okay"
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: data.message,
          icon: "error",
          confirmButtonText: "Okay"
        });
      }
    } catch (error) {
      setUpdateLoading(false);
      Swal.fire({
        title: "Error!",
        text: error.toString(),
        icon: "error",
        confirmButtonText: "Okay"
      });
      console.log("Oops Something Went Wrong", error);
    }
  };

  return (
    <main dir={dir} style={{textAlign: dir==='rtl'?'right': 'unset'}}>
      <div className="page-header">
        <div className="container d-flex flex-column align-items-center">
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to={"/"}>{"Home"}</NavLink>
                </li>
                <li className="breadcrumb-item">
                  <NavLink to={`/MyAccount`}>{t("my_account")}</NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                {t("account_details")}
                </li>
              </ol>
            </div>
          </nav>
          <h1>{t("account_details")}</h1>
        </div>
      </div>
      <div className="container account-container custom-account-container">
        <div className="row">
          <AccountSiderBar />
          <div className="col-lg-9 order-lg-last order-1 tab-content">
            <div
              className="tab-pane fade show active"
              id="address"
              role="tabpanel"
            >
              <div className="order-content">
                <h3 className="account-sub-title d-none d-md-block">
                  <i className="sicon-user align-middle mr-3" />
                  {t("account_details")}
                </h3>
                <div className="order-table-container">
                  <form action="#">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="acc-name">
                          {t("first_name")}{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="acc-name"
                            value={firstName}
                            onChange={(e) =>
                              setFirstName(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="acc-lastname">
                          {t("last_name")}{" "}
                            <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="acc-lastname"
                            value={lastName}
                            onChange={(e) =>
                              setLastName(e.target.value)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="form-group mb-2"
                      style={{ display: "none" }}
                    >
                      <label htmlFor="acc-text">
                        {"Display name"}{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="acc-text"
                        readOnly
                        placeholder={`${firstName} ${lastName}`}
                        defaultValue={`${firstName} ${lastName}`}
                        required
                      />
                      <p>
                        {
                          "This will be how your name will be displayed in the account section and in reviews"
                        }
                      </p>
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="acc-email">
                      {t("email_address")}{" "}
                        <span className="required">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="acc-email"
                        placeholder={emailAddress}
                        defaultValue={emailAddress}
                        required
                      />
                    </div>
                    <div className="change-password">
                      <h3 className="text-uppercase mb-2">
                      {t("password_change")}
                      </h3>
                      <div className="form-group">
                        <label htmlFor="acc-password">
                        {t("current_password")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="acc-password"
                          defaultValue={password}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="acc-password">
                        {t("new_password")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="acc-new-password"
                          value={newPassword}
                          onChange={(e) =>
                            setNewPassword(e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="acc-password">
                        {t("confirm_new_password")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="acc-confirm-password"
                          value={confirmPassword}
                          onChange={(e) =>
                            setConfirmPassword(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="form-footer mt-3 mb-0">
                      {updateLoading ? (
                        <button
                          type="button"
                          className="btn btn-default mr-0"
                        >
                          {t("save_changes")}...
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() =>
                            setUpdateLoading(true, UpdateProfile())
                          }
                          className="btn btn-primary mr-0"
                        >
                          {t("save_changes")}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AccountDetails;
