import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'

import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../Context/ConfigContext';
import slugify from 'slugify';
const PopularSuppliers  = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID, categories} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const {slug} = useParams();

    const [popularSuppliers, setPopularSuppliers] = useState([]);
    const [popularSuppliersLimit, setPopularSuppliersLimit] = useState(8);
   

      useEffect(() => {
      
        if(slug){
          try{
          fetch(`${gdcURL}/Suppliers/GetPouplarSuppliers`)
            .then((res) => res.json())
            .then((data) => {
              setPopularSuppliers(data.data);
            })
          }catch(err){
           
          }
        }
           
        }, []);
    
    
    return (
        <div className='container'>
                    <div className="row justify-content-center">
                        {popularSuppliers && popularSuppliers.length > 0 && 
                            popularSuppliers.slice(0, popularSuppliersLimit).map((sup, index) => (
                                <NavLink key={`popular-supplier-${index}`} to={`/Supplier-Catelog/${sup.SUP_ID}-${slugify(sup.SUP_BRAND)}`}>
                                    <div className="col-sm-1" style={{margin: '5px'}}>
                                        <ImageWithFallback 
                                            src={`${gdcURL}/sup-logo/${sup.SUP_LOGO_NAME}`} 
                                            alt={sup.SUP_BRAND} 
                                            index={index} 
                                            fallbackText={sup.SUP_BRAND}
                                        />
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                    {popularSuppliers && popularSuppliers.length > 8 && (
                        <center>
                            <span 
                                className="text-primary" 
                                style={{
                                    marginTop: '20px', 
                                    cursor: 'pointer',
                                    textDecoration: 'underline'
                                }} 
                                onClick={() => {
                                    if (popularSuppliersLimit > 8) {
                                        setPopularSuppliersLimit(8);
                                    } else {
                                        setPopularSuppliersLimit(popularSuppliers.length);
                                    }
                                }}
                            >
                                {popularSuppliersLimit > 8 ? t('show_less') : t('show_more')}
                            </span>
                        </center>
                    )}
                </div>
      
    )
 
}

const GetChildrens = (STR_ID_PARENT, rows) => {
  return rows.filter((row) => row.STR_ID_PARENT = STR_ID_PARENT);
}

const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = () => {
        setImgError(true);
    };

    const fallbackStyle = {
        textAlign: 'center',
        fontSize: '10px',
        padding: '10px',
        textOverflow: 'ellipsis',
        fontSizeAdjust: '0.545s',
    };
  
    const containerStyle = {
        position: 'relative',
        width: '120px',
        height: '65px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #CCC',
        padding: '11px',
        backgroundColor: '#FFF'
    };

    return (
        <div style={containerStyle}>
            {imgError ? (
                <div style={fallbackStyle}>{fallbackText}</div>
            ) : (
                <img 
                    style={{height: '50px', float: 'none'}} 
                    key={`img-part-${index}`} 
                    src={src} 
                    alt={alt} 
                    onError={handleError}  
                />
            )}
        </div>
    );
};
export default PopularSuppliers;