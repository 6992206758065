// const { default: MapComponent } = require("./MapComponent");
import React, { Component } from "react";
import MapComponent from "./MapComponent";

class MapHome extends Component{
    constructor(props){
        super(props);
        this.state = {
            lat: 19.8702388,
            lng: 75.2227987
        }

    }

    render() {
        return <div style={{width: '400px'}}>
            <button onClick={() => {this.setState({lat: 21.1573241, lng:78.9966149})}}>Fly to</button> 
            <MapComponent position={[this.state.lat, this.state.lng] }/></div>
        // return (<div><MapComponent /></div>)
    }
}
export default MapHome;