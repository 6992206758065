import React, { useEffect, useContext } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import { NavLink, useNavigate } from "react-router-dom";
import AccountSiderBar from "./AccountSideBar";
import { useTranslation } from 'react-i18next';

const Account = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {ClearCart} = useContext(ConfigContext);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const LogoutHandle = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("email_address");
        localStorage.removeItem("customer_id");
        localStorage.removeItem("mobile_number");
        localStorage.removeItem("first_name");
        localStorage.removeItem('carts');  
        ClearCart();
        
        navigate('/');
    }

    const dir = localStorage.getItem('dir');

    return (
        <main>
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb" dir={dir}>
                                <li className="breadcrumb-item">
                                    <NavLink to={'/'}>{t("home")}</NavLink>
                                </li>
                                <li className="breadcrumb-item">
                                    <a href={() => {}}>{t("my_account")}</a>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t("my_account")}
                                </li>
                            </ol>
                        </div>
                    </nav>
                    <h1>{t("my_account")}</h1>
                </div>
            </div>
            <div className="container account-container custom-account-container" dir={dir}>
                <div className="row">
                    <AccountSiderBar />
                    <div className="col-lg-9 order-lg-last order-1 tab-content">
                        <div className="tab-pane fade show active" id="address" role="tabpanel">
                            <div className="dashboard-content">
                                <p>
                                    {t("hello", {value:localStorage.getItem("first_name")})} 
                                </p>
                                
                                <div className="mb-4" />
                                <div className="row row-lg">
                                    <div className="col-6 col-md-4">
                                        <NavLink className="nav-link" to={`/MyAccount/Orders`} >
                                            <div className="feature-box text-center pb-4">
                                                <a href="#order" className="link-to-tab">
                                                    <i className="sicon-social-dropbox" />
                                                </a>
                                                <div className="feature-box-content">
                                                    <h3>{t("orders")}</h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <NavLink className="nav-link" to={`/MyAccount/Downloads`} >
                                            <div className="feature-box text-center pb-4">
                                                <a href="#download" className="link-to-tab">
                                                    <i className="sicon-cloud-download" />
                                                </a>
                                                <div className=" feature-box-content">
                                                    <h3>{t("downloads")}</h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <NavLink className="nav-link" to={`/MyAccount/Addresses`} >
                                            <div className="feature-box text-center pb-4">
                                                <a href="#address" className="link-to-tab">
                                                    <i className="sicon-location-pin" />
                                                </a>
                                                <div className="feature-box-content">
                                                    <h3>{t("addresses")}</h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    <div className="col-6 col-md-4">
                                        <NavLink className="nav-link" to={`/MyAccount/AccountDetails`} >
                                            <div className="feature-box text-center pb-4">
                                                <a href="#edit" className="link-to-tab">
                                                    <i className="icon-user-2" />
                                                </a>
                                                <div className="feature-box-content p-0">
                                                    <h3>{t("account_details")}</h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div>
                                    {/* <div className="col-6 col-md-4">
                                        <NavLink className="nav-link" to={`/Wishlist`} >
                                            <div className="feature-box text-center pb-4">
                                                <a href={() => {}}>
                                                    <i className="sicon-heart" />
                                                </a>
                                                <div className="feature-box-content">
                                                    <h3>{t("wishlist")}</h3>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </div> */}
                                    <div className="col-6 col-md-4">
                                        <div className="feature-box text-center pb-4" onClick={LogoutHandle} >
                                            <i className="sicon-logout" />
                                            <div className="feature-box-content">
                                                <h3>{t("logout")}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Account;
