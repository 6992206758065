import { Component, useContext, useEffect, useState } from "react";
import { ConfigContext } from "../Context/ConfigContext";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { CreateTree } from "../Helper/Utility";
import MyGarage from "./MyGarage";

const MobileHeader = ({mobileDrawerRef, closeDrawer}) => {
  const { t, i18n } = useTranslation();
      return(
      <div>
      <div className="mobile-menu-overlay" />
      <div className="mobile-menu-container" ref={mobileDrawerRef}>
        <div className="mobile-menu-wrapper">
          <span className="mobile-menu-close" onClick={() => closeDrawer()}> <i className="fa fa-times" /> </span>

         

          <nav className="mobile-nav">
          
          <SidebarMenu  closeDrawer={closeDrawer}/>
          </nav>
          <div className="social-icons">
            <NavLink to={() => {}}  className="social-icon social-facebook icon-facebook" target="_blank" rel="noreferrer" ></NavLink>
            <NavLink to={() => {}} className="social-icon social-twitter icon-twitter" target="_blank" rel="noreferrer" ></NavLink>
            <NavLink to={() => {}} className="social-icon social-instagram icon-instagram" target="_blank" rel="noreferrer" ></NavLink>
          </div>
        </div>
        {/* End .mobile-menu-wrapper */}
      </div>
      {/* End .mobile-menu-container */}
      
    </div>
      )
}


const SidebarMenu = ({closeDrawer}) =>{
  const {categories, CarStatus, gdcURL,setCategories,CartItemsQty,  CartList, isLoggedIn, lang, setLang} = useContext(ConfigContext);
  const [navHistory, setNavHistory] = useState([null])
  const [mobCategories, setMobCategories] = useState([]);
  const { t, i18n } = useTranslation();
  function hasChildren(nodeId) {
      return categories.some(item => item.STR_ID_PARENT === nodeId);
  }

// Adding STR_CHILDREN field to each object
categories.forEach(item => {
    item.STR_CHILDREN = hasChildren(item.STR_ID);
});

  return <div>
    <div style={{marginLeft: '20px'}}>{navHistory.length>1 && <NavLink to="#" onClick={() => setNavHistory(navHistory.slice(1))}><i className="fas fa-chevron-left"></i> Back</NavLink>}</div>
    <ul className="mobile-menu">
    <li className="col-sm-4" ><NavLink to={ localStorage.getItem("customer_id") ? "/MyAccount" : "Signup" } >{localStorage.getItem("customer_id") ? t('my_account') : t('signin') }</NavLink></li>
      <li className="col-sm-4" ><NavLink to={`/`}>
      
      <span className="header-icon" title="Gatage" style={{ display: "flex", alignItems: "flex-start", marginRight: "20px", cursor: "pointer", }}  >
                <svg
                  className="header-nav__icon base-svg mr-2"
                  role="img"
                  style={{
                    width: "30px",
                    height: "30px",
                    fill: "rgb(191, 203, 210)",
                  }}>
                  <use href="/assets/images/icon-sprite-bw.svg#sprite-garage-car-icon-bw"></use>
                </svg>
                <div
                  style={{ color: "rgb(191, 203, 210)" }}
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <div style={{ fontSize: "medium", fontWeight: "600" }}>
                    {t("my_cars")} 
                  </div>
                  <div style={{ fontSize: "smaller", marginTop: "-5px" }}>
                    {t("add_your_car")}
                  </div>
                </div>

                <MyGarage />
              </span>
      </NavLink></li>
      
      
    {
      categories.map((item, index) => {
        if(item.STR_ID_PARENT === navHistory[0])
        {
          return <li onClick={() => {
            if(item.STR_CHILDREN === true){
              setNavHistory([item.STR_ID, ...navHistory])
            }
          }
          } key={`mob-nav-${index}`} className="col-sm-4" >
            <NavLink onClick={() => {
              if(item.STR_CHILDREN === false){closeDrawer()}
            }} to={item.STR_CHILDREN === false?`/Spare-Parts/${slugify(item.STR_NODE_NAME)}-${item.STR_ID}`: '#'}>{item.STR_NODE_NAME}</NavLink>
          </li>
        }
      })
    }
  </ul>
  </div>
}

const SidebarMenux = () =>{
  const {categories, treeCategories, CarStatus, gdcURL,setCategories,CartItemsQty,  CartList, isLoggedIn, lang, setLang} = useContext(ConfigContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dir = localStorage.getItem('dir');
  
 
const GetSubMenu = (childrens) => {
    
  if(childrens.length > 0){
    return <ul >
      {
        childrens.map((child, index) => {
          
          var url = child.children.length > 0 ?'#': `/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${child.STR_ID}`;
          return <li key={`${child.STR_ID}-${index}`}>
            <NavLink  to={url}>{child.STR_NODE_NAME}</NavLink>
            {child.children.length>0?GetSubMenu(child.children):<></>}
            </li>
        })
      }
    </ul>
  }
  return <div></div>
}
  return (
    <ul className="mobile-menu">
      {
        treeCategories.map((category, index) => {
          return <li key={`${category.STR_ID}-${index}`}>
              <NavLink to="#">{category.STR_NODE_NAME}</NavLink>
              {GetSubMenu(category.children)}
            </li>
        })
      }
    </ul>
  );
}


export  { SidebarMenu};
export default MobileHeader;