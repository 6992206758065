exports.GetCars = () => {
    var cars = localStorage.getItem('cars');
    if(cars !== "" && cars != null){
        return JSON.parse(cars);
    }
    return true;
}

exports.AddCar = (car) => {  

    var cars = localStorage.getItem('cars');
  
    if (cars !== "" && cars != null) {
      cars = JSON.parse(cars);
      // Reset selected flag for all cars
      var resetCars = [];
      cars.forEach((element) => {
        element.selected = 0;
        resetCars.push(element);
      });
      localStorage.setItem('cars', JSON.stringify(resetCars));
      // Check if the car is already in the list
      const existingCar = cars.find((element) => element.car_id === car.car_id);
      if (existingCar) {
        // If the car is already in the list, mark it as selected
        existingCar.selected = 1;
      } else {
        // If the car is not in the list, add it and mark as selected
        car.selected = 1;
        cars.push(car);
      }
    } else {
      // If the local storage is empty, add the car and mark as selected
      cars = [car];
      car.selected = 1;
    }
  
    localStorage.setItem('cars', JSON.stringify(cars));
    return true;
  };
  
  
  // exports.DeleteCar = (car_id) => {
  //   var cars = localStorage.getItem('cars');
  //   cars = JSON.parse(cars);
  
  //   if (cars) {
  //     var index = cars.findIndex(car => car.car_id === car_id);
      
  //     if (index !== -1) {
  //       cars.splice(index, 1);
  //       localStorage.setItem('cars', JSON.stringify(cars));
  //       this.GetCars();
  //       return true;
  //     }
  //   }
  
  //   return false; // Car with specified ID not found in local storage
  // }

  exports.DeleteCar = (car_id) => {
    var cars = localStorage.getItem('cars');
    cars = JSON.parse(cars);
  
    if (cars) {
      var index = cars.findIndex(car => car.car_id === car_id);
  
      if (index !== -1) {
        // Check if the car to be deleted is currently selected
        const isSelected = cars[index].selected === 1;
  
        // Remove the car from the array
        cars.splice(index, 1);
  
        // If the deleted car was selected, update the selected flag for the next car
        if (isSelected && cars.length > 0) {
          const nextSelectedIndex = index < cars.length ? index : cars.length - 1;
          cars[nextSelectedIndex].selected = 1;
        }
  
        // Update local storage and get the updated car list
        localStorage.setItem('cars', JSON.stringify(cars));
        this.GetCars();
        return true;
      }
    }
  
    return false; // Car with specified ID not found in local storage
  };
  
exports.SelectCar = (car_id) => {
    var cars = localStorage.getItem('cars');
    cars = JSON.parse(cars);
    cars.forEach(car => {
        car.selected = 0;
        if(car.car_id === car_id){
            car.selected = 1;
            this.GetCars();
        }
    });
    localStorage.setItem('cars', JSON.stringify(cars));
    return true;
}

