import React, { useRef,useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

function MapComponent(props) {
  const mapRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      mapRef.current.flyTo(props.position, mapRef.current.getZoom());
    }, 1000);
  });

  return (
    <div>
      <MapContainer
      whenReady={() => {
      
      }}
      whenCreated={() => {
        
      }}
      center={props.position} zoom={13} style={{ height: 'calc(100vh - 100px)' }} ref={mapRef}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={props.position}>
          <Popup open={true}>
            Punchin IN: datetime <br /> Punchin OUT: datetime
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export default MapComponent;
