import { t } from "i18next";
import { useState, useContext, useEffect  } from "react"; 
import { useTranslation } from "react-i18next";
import axios from "axios";

const { NavLink, useLocation } = require("react-router-dom")
const { ConfigContext } = require("../../Context/ConfigContext")

const Pages = ({page}) => {
    const {apiURL, token, lang} = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');
    const [PageContent, setPageContent] = useState();
    const [PageTitle, setPageTitle] = useState();
    const location = useLocation();
    useEffect(() => {
      LoadPolicyInfo();
      window.scrollTo(0, 0);
    }, [location])
    const LoadPolicyInfo = () => {
      const headers = {
          'token': `${token}`,
          'Content-Type': 'application/json',
      };

      axios.get(`${apiURL}/Home/GetPages?page=${page}&lang=${lang??'en'}`, { headers })
          .then((response) => {
            
              const row = response.data.data[0];
              // setPageID(row.page_id);
              // setPageName(row.page_name);
              setPageContent(row.page_content);
              setPageTitle(row.page_name);
          });
  };
    return (
          <div>
          
          <nav aria-label="breadcrumb" className="breadcrumb-nav">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={() => {}}><i className="icon-home" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page">{PageTitle}</li>
              </ol>
            </div>{/* End .container */}
          </nav>
          <div className="about-section">
          
            <div className="row justify-content-center">
              <div className="col-8">
              <h2>{PageTitle??'...'} </h2>
              <iframe
                  srcDoc={PageContent}
                  style={{
                    width: '100%',
                    border: 'none',
                    height: 'auto',
                    minHeight: '500px'  // Adjust as needed
                  }}
                  title={PageTitle}
                  onLoad={(e) => {
                    // Automatically adjust iframe height to content
                    e.target.style.height = 
                      e.target.contentWindow.document.documentElement.scrollHeight + 'px';
                  }}
                />
              </div>
            </div>
           
          </div>
         
        </div>
          )
}

export default Pages;