import React, { useState, useEffect, useContext,useRef } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Suspense } from "react";
import axios from "axios";
import { ConfigContext } from "../Context/ConfigContext";
import MobileHeader from "./MobileHeader";
import { GetCars } from "../Components/Cars";
import {jwtDecode} from "jwt-decode";
import Sidebar from "./Sidebar";
import MobileSearchBar from "../Components/SearchBar/MobileSearchBar";

const AppLayout = () => {
  const { 
    UpdateCartList, setLang, setWindowResize, setCartItemsQty, 
    setLoginStatus, apiURL, setCategories, lang, setCars, setMakes, ChangeCarStatus, UpdateCarList, resizeState, CarStatus 
  } = useContext(ConfigContext);

  const [categories, setCategoriesState] = useState([]);
  const [cars, setCarsState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  
  // Initialize localStorage settings
  useEffect(() => {
    localStorage.setItem('dir', 'ltr');
    if (localStorage.getItem('lang') === 'ar') {
      localStorage.setItem('dir', 'rtl');
    }

    setLang(localStorage.getItem('selectedLanguage') ? localStorage.getItem('selectedLanguage') : 'en');
    setCartItemsQty();
    setWindowResize({
      isMobile: window.innerWidth <= 768,
      loading: false,
      error: null,
    });
    CheckLogin();
  }, []); // Empty dependency array ensures it runs once like componentDidMount

  // Check login status
  const CheckLogin = () => {
    if (!localStorage.getItem('token')) {
      setLoginStatus(false);
      return false;
    }
    const token = localStorage.getItem('token');
    const decodedToken = jwtDecode(token);
    const currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      setLoginStatus(false);
      return false;
    } else {
      setLoginStatus(true);
    }
  };

  // Fetch categories
  const GetCategories = () => {
    axios.get(`${apiURL}/Categories/GetCategories?country_id=225&lang=${lang}`)
      .then(response => {
        const data = response.data;
        setCategories(data.data);
        setCategoriesState(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  // Fetch cars
  const GetCarsAPI = async () => {
    axios.get(`${apiURL}/Parts/GetCars`, null, { token: localStorage.getItem('token') })
      .then(response => {
        const data = response.data;
        setCars(data.data);
        const mMakes = [];
        data.data.reduce((acc, car) => {
          const brand = car.car_brand;
          if (!acc[brand]) {
            acc[brand] = [];
            mMakes.push({ value: car.car_brand, label: car.car_brand });
          }
          acc[brand].push(car);
          return acc;
        }, {});
        setMakes(mMakes);
        setCarsState(data.data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  };

  // Check car status
  const CheckStatus = () => {
    if (GetCars().length > 0) {
      ChangeCarStatus(true);
      UpdateCarList(GetCars());
    }
  };


  const mobileDrawerRef = useRef(null);
  const openDrawer = () => {
    if (mobileDrawerRef.current) {
      mobileDrawerRef.current.style.transform = "translateX(0)";
      mobileDrawerRef.current.style.zIndex = "10000000000000000";
    }
  }
  const closeDrawer = () => {
    if (mobileDrawerRef.current) {
      mobileDrawerRef.current.style.transform = "translateX(-100%)";
    }
  }

  const [isMobile, setIsMobile] = useState(false);

  // Function to check the current window size
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Set to mobile if width <= 768px
  };

  useEffect(() => {
    handleResize(); // Check initial size
    window.addEventListener('resize', handleResize); // Add event listener

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <Sidebar />
        <Header isMobile={isMobile} openDrawer={openDrawer} closeDrawer={closeDrawer}/>
        {
          isMobile && <MobileSearchBar />
        }
        <main className="main">
          <Suspense fallback={<div>Loading...</div>}>
            <Outlet />
          </Suspense>
        </main>
        <Footer />
      </div>
      <MobileHeader mobileDrawerRef={mobileDrawerRef} closeDrawer={closeDrawer}/>
    </>
  );
};

export default AppLayout;
