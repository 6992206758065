// import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider, } from "react-router-dom"
import Page404 from './Layout/Page404';
import {ConfigContext, ConfigProvider} from '../src/Context/ConfigContext'

import routes from './Routes/Routes'
import AppLayout from './Layout/AppLayout';
import './i18n'
import {jwtDecode} from 'jwt-decode';
import { useEffect } from 'react';
import axios from 'axios';
import { useContext } from 'react';
// import { LanguageProvider } from './Context/LanguageContex';

function App() {
  if(!localStorage.getItem('currency')){
    localStorage.setItem('currency', 'aed');
  }
  if(localStorage.getItem('token')){
    const decodedToken = jwtDecode(localStorage.getItem('token'), {complete: true});
    const currentTime = Date.now() / 1000; 
    if (decodedToken.exp < currentTime) {
      localStorage.setItem('token', '');
      localStorage.setItem('customer_id', '');
      localStorage.setItem('email_address', '');
      localStorage.setItem('first_name', '');
      localStorage.setItem('mobile_number', '');
      // alert("token expired")
      const expiryTimeInSeconds = decodedToken.exp - currentTime;
      const expiryTimeInMinutes = Math.floor(expiryTimeInSeconds / 60);
      
    }else{
      const expiryTimeInSeconds = decodedToken.exp - currentTime;
      const expiryTimeInMinutes = Math.floor(expiryTimeInSeconds / 60) / 60;
      // alert(expiryTimeInMinutes)
      // alert(decodedToken.exp+"-"+currentTime)
    }
  }
  
  const router = createBrowserRouter([
    {
      element: <AppLayout />,
      errorElement: <Page404 />,
      // specify the routes defined in the
      // routing layer directly
      children: routes
    },
  ])
  useEffect(() => {
   if( isTokenExpired(localStorage.getItem('token'))){
    localStorage.setItem('token', '');
    localStorage.setItem('customer_id', '');
    localStorage.setItem('email_address', '');
    localStorage.setItem('first_name', '');
    localStorage.setItem('mobile_number', '');
   }
  }, [])
  const isTokenExpired = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
     
      return decoded.exp < currentTime; // Check if token is expired
    } catch (error) {
      console.error("Invalid token:", error);
      return true; // Treat invalid tokens as expired
    }
  };
  return (
    // <LanguageProvider>      
      <ConfigProvider>
        <RouterProvider router={router} />
      </ConfigProvider>
    // </LanguageProvider>
  )
}

export default App;
