import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';

import slugify from "slugify";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ConfigContext } from "../../../Context/ConfigContext";
import CatelogModal from "./CatelogModal";
import TabsItems from "./TabsItems";
import Tabs from "./Tabs";
import axios from "axios";
const MobSection = ({tabNameForeign}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID, setCategories, categories, treeCategories} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    
    const [p_subs, setPsubs] = useState(null);
    const [p_index, setPindex] = useState(0);
    const [p_title, setPTitle] = useState(null);
    const [p_STR_ID, setPSTRID] = useState(null);
    // const [categories, setCategories] = useState([]);
    const [car, setCar] = useState([]);
    const [loading, setLoading] = useState(true);
    const {s_str_id} = useParams();
    const [stridtree, setSTRIDTree] = useState(s_str_id?[Number(s_str_id),null]:[null]);
   
    
    useEffect(() => {
      
      window.scrollTo(0, 0);
      if(localStorage.getItem('pc_id') && localStorage.getItem('pc_id')!=='' && localStorage.getItem('pc_id')!=='0')
      {
        // alert(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
        setLoading(true);
        axios.get(`${gdcURL}/Categories/GetCategoriesByCar?pc_id=${PCID}&lang=${locale}`)
        .then(data => {
          
          setCategories(JSON.parse(JSON.stringify(data.data.data))); 
          setLoading(false);
        })
      }else{
        setLoading(true);
        axios.get(`${gdcURL}/Categories/GetCategories?lang=${locale}`)
        .then(data => {
          
          setCategories(JSON.parse(JSON.stringify(data.data.data))); 
          setLoading(false);
        })
      }
         
      }, [PCID]);
    
    
    const RemoveCategorySTRID = () => {
        if( stridtree[0] !== null){
            setSTRIDTree(stridtree.slice(1));
        }
    }
   
    const car_info = localStorage.getItem('car_info')?JSON.parse(localStorage.getItem('car_info')): null;
    
   

      const [showModal, setShowModal] = useState(false);

      const handleShow = (subs, title, STR_ID, index) => {
          setPTitle(title);
          setPsubs(subs);
          setPSTRID(STR_ID);

          setPindex(Math.floor((( index )/ 6)));
      }

      const [selectedCategories, setSelectedCategories] = useState([]);
      const [selectedCategoryName, setSelectedCategoryName] = useState("...");
      const [selectedCategoryID, setSelectedCategoryID] = useState(0);
      const handleShowMenu = (subs, title, STR_ID) => {
        
        setSelectedCategoryName(title);
        setSelectedCategories(subs);
        setSelectedCategoryID(STR_ID)
        setShowModal(true);
        
      }
    return(<>
    <section className="feature-boxes-container" style={{background: '#f7f7f7'}}>
   
      <div className="container" data-animation-name="fadeInUpShorter" >
        <div className="row">
            {treeCategories && treeCategories.map((item) => (
                <div onClick={() => handleShowMenu(item.children, item.STR_NODE_NAME, item.STR_ID)} key={item.STR_ID} className="col-6" style={{backgroundColor: '#f9fafb', border: '5px solid rgb(247, 247, 247)'}}>
                    <img 
                        alt={item.STR_NODE_NAME} 
                        src={`${gdcURL}/category-images/600x600/${item.STR_ID}`} 
                        style={{ margin: 'auto', display: 'block' }} 
                    />
                    <div className="card-text text-center pl-2 pr-2 mb-1" style={{ height: 70 }}>
                        <span 
                            style={{ 
                                fontFamily: "Montserrat, Arial, sans-serif", 
                                fontSize: 17, 
                                fontWeight: 500, 
                                color: "rgb(3, 47, 74)" 
                            }} 
                        >
                            {item.STR_NODE_NAME}
                        </span>
                    </div>
                </div>
            ))}
        </div>

      
      </div>
      <CatelogModal subs={selectedCategories} title={selectedCategoryName} STR_ID={selectedCategoryID} modalStatus={showModal} />
    </section>
    </>)
  
}

export default MobSection