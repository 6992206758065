import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useNavigation, useParams } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { useTranslation } from "react-i18next";

const EmailVerification = () => {
    const {token} = useParams();
    const {apiURL} = useContext(ConfigContext);
    const navigate = useNavigate();
    const [VerifiedSuccess, setVerifiedSuccess] = useState(false);
    const [VerifiedFailed, setVerifiedFailed] = useState(false);
    const [LoadingStatus, setLoadingStatus] = useState(true);
    const {t} = useTranslation();
    useEffect(() => {
        
        verify();
    }, [])
    const verify = async() => {
        const { data } = await axios.post( `${apiURL}/Account/EmailVerification`,  { token: token} );
        console.log(data)
        if (data.success) {
            console.log(data)
            setLoadingStatus(false);
            setVerifiedSuccess(true);
            // navigate('/Signup');
        }else{
            setVerifiedFailed(true);
            setLoadingStatus(false);
        }
    }
    return (
        <div className="p-5">

            {VerifiedSuccess && <center>
                <i className="fas fa-check-double" style={{color: 'green', fontSize: '132px'}}></i>
                <br />
                <br />
                <button className="btn btn-primary">{t('login')}</button>
            </center>}

            {VerifiedFailed && <center>
                <i className="fas fa-times" style={{color: 'red', fontSize: '132px'}}></i>
                <br />
                <br />
                <button className="btn btn-primary">{t('home')}</button>
            </center>}
            {LoadingStatus && <center>
                <br />
                <br />
                Please wait while verifying
                <br />
                <br />
                
            </center>}
            
        </div>
    )
}
export default EmailVerification;