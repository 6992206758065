import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import slugify from "slugify";
import { ConfigContext } from "../../Context/ConfigContext";
import { useTranslation } from "react-i18next";

const MakesModels = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const locale = localStorage.getItem('lang');
    const {gdcURL, PCID} = useContext(ConfigContext);
    const dir = localStorage.getItem('dir');
    const [models, setModels] = useState([]);
    const {mfa_id, mf_name} = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
        fetch(`${gdcURL}/Cars/GetModels?mfa_id=${mfa_id}`)
          .then((res) => res.json())
          .then((data) => {
            setModels(data.data); 
          })
      }, [mfa_id]);
    
    

    return (
        <main className="main" dir={dir}>
            <div className="container">
                <div className="container">
                <nav aria-label="breadcrumb" className="breadcrumb-nav">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><NavLink to={'/'}><i className="icon-home" /></NavLink></li>
                        <li className="breadcrumb-item"><NavLink to={() => {}}>{mf_name}</NavLink></li>
                        <li className="breadcrumb-item active" aria-current="page"></li>
                    </ol>
                </nav>
                </div>
                

                <div className="container">
                    <div className="row d-flex flex-wrap">
                        {models && models.map(item => (
                            <div className="col-3 col-md-3  d-flex align-items-stretch mt-2" key={`supplier-logos-${item.MS_ID}`} >
                                <NavLink className={`itemBB w-100 h-100`} to={`/Supplier-Catelog/${item.MS_ID}`}>
                                    <ImageWithFallback src={`${gdcURL}/car-ms-images/${item.MS_ID}`} alt={item.MS_NAME} index={item.MS_ID} fallbackText={item.MS_NAME}/>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    )
}
const ImageWithFallback = ({ src, index, alt, fallbackText }) => {
    const [imgError, setImgError] = useState(false);
  
    const handleError = () => {
      setImgError(true);
    };
    const fallbackStyle = {
      textAlign: 'center',
      fontSize: '10px',
      padding: '10px',
      wordBreak: 'break-word',
      maxWidth: '90%',
      
    };
  
    const containerStyle = {
      position: 'relative',
      
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    //   minHeight: '63vh'
    };
    return (
      <div style={containerStyle}>
        {imgError ? (
          <h6>{fallbackText}</h6>
        ) : (
          <div style={{textAlign: 'center'}}>
            <img style={{ float: 'none'}} key={`img-part-${index}`} src={src} alt={alt} onError={handleError}  />
            <h6>{fallbackText}</h6>
            </div>
         
        )}
      </div>
    );
  };

export default MakesModels;