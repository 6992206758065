exports.AddToCart = (item_id, item_number, item_title, item_mrp, item_price, item_qty, item_img_url, item_url) => {
    
    var carts = localStorage.getItem('carts')?JSON.parse(localStorage.getItem('carts')): [];
    
    if (carts !== "" && carts !== undefined && carts !== null) {
        // carts = JSON.parse(carts);
        const index = carts.findIndex(item => item.item_number === item_number);
        if (index !== -1) {
            carts[index].item_qty = carts[index].item_qty + item_qty;
            localStorage.setItem('carts', JSON.stringify(carts));
            return true;
        }else{
            carts.push({item_id: item_id, item_number: item_number, item_title: item_title, item_mrp: item_mrp, item_price: item_price, item_qty: item_qty, item_img_url: item_img_url, item_url: item_url});
            localStorage.setItem('carts', JSON.stringify(carts));
            return true;
        }
    }else{
        carts.push({item_id: item_id, item_number: item_number, item_title: item_title, item_mrp: item_mrp, item_price: item_price, item_qty: item_qty, item_img_url: item_img_url, item_url: item_url});
        localStorage.setItem('carts', JSON.stringify(carts));
        return true;
    }
    
}


exports.DeleteCartItem = (index) => {
    
    var carts = localStorage.getItem('carts');
    console.log("carts",carts);
    carts = JSON.parse(carts);
    carts.splice(index, 1);
    localStorage.setItem('carts', JSON.stringify(carts));
   
    return true;
}

exports.QtyUpdate = (index, qty) => {
    var carts = localStorage.getItem('carts');
    carts = JSON.parse(carts);
    
    carts[index].item_qty = qty;
    localStorage.setItem('carts', JSON.stringify(carts));
    return true;
}

exports.GetCarts = () => {
    var carts = localStorage.getItem('carts');
    
    if(carts === "" || carts === null){
      return [];
    }
    
    carts = carts!==""?JSON.parse(carts):[];
    // console.log("carts", carts)
    return carts;
}