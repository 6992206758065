import React, { Component } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import Select from 'react-select'
import { AddCar, GetCars } from "../../Components/Cars";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
class SelectCarWeb extends Component{

  static contextType = ConfigContext;
   constructor(props) {
    super(props);

    this.state = {
      selectedMake: '',
      selectedYear: '',
      selectedModel: '',
      selectedEngine: '',
      makes: [],
      vin: '',
      makeOpen: false,
      yearOpen: false,
      modelOpen: false,
      engineOpen: false,
      // years: [],
      // models: [],
      // engines: [],
      // cars: [],
      selectedCar: '',
    };
  }
  componentDidMount(){
    
  }
  
validateInputs = () => {
  const { selectedMake, selectedYear, selectedModel, selectedEngine, vin } = this.state;

  // Check if VIN is disabled (other four fields are enabled)
  if (!vin) {
    // Validation logic for the other four fields
    if (!selectedMake || !selectedYear || !selectedModel || !selectedEngine) {
      alert('Please select all car details');
      return false;
    }
  } else {
    // Validation logic for VIN
    if (!vin) {
      alert('Please enter VIN');
      return false;
    }
  }

  // You can add more specific validations as needed

  return true;
}

  handleMakesChange = (selectedMake) => {
    
    const { cars, setYears } = this.context;
    this.setState({ selectedMake:selectedMake }, () => {
      var filteredCars = cars.filter((car) => car.car_brand === this.state.selectedMake.value);
      var groupedCars = [];
      var tempGroupedCars = [];
      var mYears = [];
      filteredCars.forEach(function (car) {
        if (!tempGroupedCars[car.car_year]) {
          tempGroupedCars[car.car_year] = [];
          groupedCars.push(car);
          mYears.push({ value: car.car_year, label: car.car_year },);
        }
      });
      mYears.sort((a, b) => a.value - b.value);
      setYears(mYears);
      this.setState({makeOpen: false, yearOpen: true, modelOpen: false, engineOpen: false});
    }
      
    );
    
  }
  handleYearsChange = (selectedYear) => {
    const { cars, setModels } = this.context;
    this.setState({ selectedYear:selectedYear }, () => {
      var filteredCars = cars.filter((car) => car.car_brand === this.state.selectedMake.value && car.car_year === this.state.selectedYear.value);
      var groupedCars = [];
      var tempGroupedCars = [];
      var mModels = [];
      filteredCars.forEach(function (car) {
        if (!tempGroupedCars[car.car_model]) {
          tempGroupedCars[car.car_model] = [];
          groupedCars.push(car);
          mModels.push({ value: car.car_model, label: car.car_model },);
        }
      });
      setModels(mModels);
      
      this.setState({makeOpen: false, yearOpen: false, modelOpen: true, engineOpen: false});
    }
      
    );
  }

  handleModelsChange = (selectedModel) => {
    const { cars, setEngines } = this.context;
    this.setState({ selectedModel:selectedModel }, () => {
      var filteredCars = cars.filter((car) => car.car_brand === this.state.selectedMake.value && car.car_year === this.state.selectedYear.value && car.car_model === this.state.selectedModel.value);
      var groupedCars = [];
      var tempGroupedCars = [];
      var mEngines = [];
      filteredCars.forEach(function (car) {
        if (!tempGroupedCars[car.car_model]) {
          tempGroupedCars[car.car_model] = [];
          groupedCars.push(car);
          mEngines.push({ value: car, label: car.car_engine },);
        }
      });
      setEngines(mEngines);
      
      this.setState({makeOpen: false, yearOpen: false, modelOpen: false, engineOpen: true});
    }
      
    );
  }
  handleEnginesChange = (selectedEngine) => {
    this.setState({ selectedEngine, selectedCar: selectedEngine, engineOpen: false }, () => {
    });
  }
   
   handleGo() {
    const { ChangeCarStatus, UpdateCarList } = this.context;
    if (this.validateInputs()) {
    if (this.state.vin) {
      this.searchByVin(this.state.vin);
    } else {
      AddCar(this.state.selectedCar.value); 
      ChangeCarStatus(true);
      this.props.navigate(`/Catalog/${this.state.selectedCar.value.car_id}/${this.state.selectedCar.value.car_brand.replace(/\s/g, "_")}/${this.state.selectedCar.value.car_year}/${this.state.selectedCar.value.car_model.replace(/\s/g, "_")}/${this.state.selectedCar.value.car_engine.replace(/\s/g, "_")}`);
      UpdateCarList( GetCars() ); 
      this.resetFields();
    }
    }
  }
   searchByVin = async (vin) => {
    const { apiURL} = this.context;
    console.log("vin", vin);
    try {
      const response = await axios.get(`${apiURL}/Parts/GetVINCar?country_id=225&lang=en&vin=${vin}`);
      console.log("response",response);
      const carData = response.data.data;
      if (carData) {
        this.props.navigate(`/VIN/${vin}`);
      } else {
        this.props.navigate(`/VIN/${vin}`);
        console.warn('Car data or VIN not found in the response:', carData);
      }
    } catch (error) {
      console.error('Error fetching car data:', error);
      // setCarDataByVin(null);
    }
  };
  resetFields = () => {
    const { setYears, setModels, setEngines } = this.context;
  
    this.setState({
      selectedMake: null,
      selectedYear: null,
      selectedModel: null,
      selectedEngine: null,
      vin: "",
    }, () => {
      // Reset dropdown options to initial state
      setYears([]);
      setModels([]);
      setEngines([]);
    });
  };
   toggleDropdown = () => {
    this.setState({openYear: !this.state.openYear});
  };

  render(){
    const dir = localStorage.getItem('dir');
    const { makes, years, models, engines,  } = this.context;
    const isButtonDisabled = !(this.state.selectedCar || this.state.vin);
    const { t } = this.props;
    return (
    <main className="main" style={{direction: dir, textAlign: dir === 'rtl'?'right': 'left'}}>
      <div className="container-fluid">
        <hr style={{ display : 'none' }}/>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div style={{ borderRadius: "20px", background: "#cc000b", padding : "10px" }}>
            <div className="row">
              <div
                className="col-lg-2 col-md-2 col-sm-2 d-flex flex-wrap  justify-content-center align-items-center"
                id="select-car-row"
              >
                <svg
                  viewBox="0 0 32 32"
                  className="sc-1k8rycc-0 huZjNT"
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    padding: "5px",
                    border: "2px solid white",
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                  }}
                >
                  <path
                    d="M27.867 12.667h-1.733c-0.667-0.933-0.667-0.933-1.467-2.267-1.2-2-3.2-2.4-3.2-2.4l-5.333-0.133-5.467 0.133c0 0-2 0.533-3.333 2.533-0.933 1.333-0.667 0.8-1.467 2.133h-1.733c-0.533 0-0.933 0.4-0.933 0.933 0 0 0 0 0 0v0.133c0 0.533 0.4 0.933 0.933 0.933h1.067l-0.533 1.6c0 0-0.267 0.533-0.267 1.867v5.867c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-0.8h13.867v0.8c0 0.667 0.533 1.333 1.333 1.333h2.133c0.667 0 1.333-0.533 1.333-1.333v-6c0-1.333-0.267-1.867-0.267-1.867l-0.667-1.467h1.067c0.533 0 0.933-0.4 0.933-0.933v-0.133c-0.133-0.533-0.533-0.933-1.067-0.933zM9.067 19.467l-2.8-0.133c-0.4-0.133-0.667-0.4-0.8-0.8s-0.133-1.067 0-1.467c0.267-0.8 1.2-0.4 1.733-0.133 0.8 0.267 1.6 0.667 2.4 0.933 0.533 0.133 0.8 0.533 0.8 1.067-0.133 0.667-0.933 0.533-1.333 0.533zM16 14.533c-4.133 0-8.667-0.8-8.667-0.8l1.333-2.667c0.533-1.467 2.667-1.6 2.667-1.6l4.667-0.133h4.667c0 0 2 0.133 2.533 1.6l1.333 2.667c0.133 0.133-4.4 0.933-8.533 0.933v0zM26.533 18.533c-0.133 0.4-0.4 0.667-0.8 0.8l-2.8 0.133c-0.533 0-1.2 0-1.333-0.533s0.267-0.933 0.8-1.067c0.8-0.267 1.6-0.667 2.4-0.933 0.533-0.133 1.467-0.533 1.733 0.133 0.133 0.4 0.133 1.067 0 1.467z"
                    fill="white"
                  ></path>
                </svg>
                <span className="d-flex justify-content-around align-items-center ml-2">
                  <h5 className="m-0 text-white">
                  
                    {t("select_your_car")}
                    </h5>
                </span>
              </div>
              <div className="col-lg-8 col-md-8 col-sm-8 d-flex justify-content-around align-items-center pb-3">
              <div className="car-inputs" style={{    width: '100%', zIndex: 100}}>
                <Select  placeholder={t("select_make")} value={this.state.selectedMake} isDisabled={this.state.vin} onChange={this.handleMakesChange} styles={{zIndex: '100000000'}} options={makes}></Select>
                </div>
                <div className="car-inputs" style={{width: '100%', marginLeft: '5px', zIndex: 100}}>
                  <Select onFocus={() => this.setState({yearOpen: true})}  onBlur={() => this.setState({yearOpen: false})} menuIsOpen={this.state.yearOpen} placeholder={t("select_year")} value={this.state.selectedYear} isDisabled={this.state.vin} onChange={this.handleYearsChange} styles={{zIndex: '100000000'}} options={years}></Select>
                </div>
                <div className="car-inputs" style={{width: '100%', marginLeft: '5px', zIndex: 100}}>
                  <Select onFocus={() => this.setState({modelOpen: true})}  onBlur={() => this.setState({modelOpen: false})} menuIsOpen={this.state.modelOpen} placeholder={t("select_model")} value={this.state.selectedModel} isDisabled={this.state.vin} onChange={this.handleModelsChange} styles={{zIndex: '100000000'}} options={models}></Select>
                </div>
                <div className="car-inputs" style={{width: '100%', marginLeft: '5px', zIndex: 100}}>
                  <Select onFocus={() => this.setState({engineOpen: true})}  onBlur={() => this.setState({engineOpen: false})} menuIsOpen={this.state.engineOpen} placeholder={t("select_engine")} value={this.state.selectedEngine} isDisabled={this.state.vin} onChange={this.handleEnginesChange}  styles={{zIndex: '100000000'}} options={engines}></Select>
                </div>
                
                <div className="car-inputs" >
                  <span className="h4 text-light mx-1">
                      {t("or")}
                    {/* {t('OR')} */}
                  </span>
                </div>
                <div className="car-inputs" style={{width: '100%',  zIndex: 100}}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("enter_vin")}
                    style={{marginBottom: 'unset',marginLeft:"2px", height: '33px',borderRadius : '4px', padding:"17px 10px"}}
                    // placeholder={t('EnterVIN')}
                    // disabled={selectedMake}
                    disabled={this.state.selectedMake ? true : false}
                    value={this.state.vin}
                    onChange={(e) => this.setState({vin: e.target.value})}
                  />
                </div>
              </div>
              <div className="ml-0 col-lg-2 col-md-2 col-sm-2 d-flex align-items-center">
                <div
                  data-dismiss="modal"
                  className={`btn btn-outline-light ${isButtonDisabled ? 'disabled' : ''}`}
                  style={{
                    width: "130px",
                    fontSize: "15px",
                    borderRadius: "50px",
                    fontWeight: "bold",
                  }}
                  onClick={() => this.handleGo(this.state.selectedCar)}
                  
                  >
                  {t("go")}
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    )
  }
}
function WithNavigate(props) {
  let navigate = useNavigate();
  return <SelectCarWeb {...props} navigate={navigate} />
}
export default withTranslation()(WithNavigate)