import { NavLink } from "react-router-dom"
import {HashLoader} from "react-spinners";

const PartCardShimmer = () => {
    return (
        
        <div className="product-default" data-animation-name="fadeInRightShorter">
              
              <div className="product-details" style={{backgroundColor: '#EEE'}}>
                    <div style={{height: 280, width: 280, backgroundColor: '#EEE'}} >

                    <div className="loading-overlay">
                        <div className="bounce-loader">
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                        </div>
                    </div>
                    </div>
                    <div className="category-list">
                    <NavLink to={() => false} className="product-category"></NavLink>
                    </div>
                
                <h3 className="product-title" 
                >
                  <NavLink to={() => false}></NavLink>
                </h3>
                <h3 className="product-title" style={{ display:"none" }}>
                  <NavLink to={() => false}></NavLink>
                </h3>
                <h3 className="product-title">
                  <NavLink to={() => false}></NavLink>
                </h3>
                <div className="ratings-container" style={{visibility: 'hidden'}}>
                  <div className="product-ratings">
                    <span className="ratings" style={{width: '80%'}} />
                    {/* End .ratings */}
                    <span className="tooltiptext tooltip-top" />
                  </div>
                  {/* End .product-ratings */}
                </div>
                {/* End .product-container */}
                <div className="price-box" style={{backgroundColor: '#EEE'}}>
                  <del className="old-price"></del>
                  <span className="product-price"></span>
                </div>
                {/* End .price-box */}
               
                <div className="product-action" style={{backgroundColor: '#EEE'}}>
                  
                </div>
              </div>
              {/* End .product-details */}
            </div>
    )
}
const NoRecords = (props) => {
  return (

    <div className="noresult" style={{display: 'block', width: '100%'}}>
      <div className="text-center">
        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{width: '75px', height: '75px'}} />
        <h5 className="mt-2">Sorry! No Result Found</h5>
        {/* <p className="text-muted mb-0">We've searched more than 150+ contacts We did not find any contacts for you search.</p> */}
      </div>
    </div>
  );
}

const Spinner = (props) => {
  return <HashLoader color="#cc000b" />
}

export {PartCardShimmer, NoRecords, Spinner}