import axios from "axios";
import React, { useState, useEffect, useContext, useTransition } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { ConfigContext } from "../../Context/ConfigContext";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const ForgetPassword = () => {
    const { apiURL, setLoginStatus, setToken } = useContext(ConfigContext);
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dir = localStorage.getItem('dir');
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [messageType, setMessageType] = useState(0);
    const [responseMessage, setResponseMessage] = useState(true);
    const {reset_password_token} = useParams();
    const handleLogin = async(e) => {
        e.preventDefault();
        const { data } = await axios.post( `${apiURL}/Account/ForgetPassword`, null, { params: {email_address: email} } );
        
        if (data.success) { 
            setMessageType(1);
            setResponseMessage(data.message);
            setEmail("");
        }else{
            setMessageType(1);
            setResponseMessage(data.message);
            setLoading(false);
        }
    }
    return (
        <main className="main" style={{ direction: dir, textAlign: dir === 'rtl' ? 'right' : 'left' }}>
            <Helmet>
                <title>Forgot Your Password? - {t('site_name')}</title>
                <meta name="description" content={`Can't remember your password? Reset it easily with ${t('site_name')} and regain access to your account.`} />
                
                <meta name="robots" content="noindex, nofollow" />
                <meta property="og:title" content={`Forgot Your Password? - ${t('site_name')}`} />
                <meta property="og:description" content={`Reset your password securely on ${t('site_name')} and access your account in a few steps.`} />
                <meta property="og:url" content={`${t('site_url')}/Forget-Password`} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={`${t('site_url')}/static/images/forgot-password.png`} />
                
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`Forgot Your Password? - ${t('site_name')}`} />
                <meta name="twitter:description" content={`Having trouble logging in? Reset your password quickly on ${t('site_name')} and regain access to your account.`} />
                <meta name="twitter:image" content={`${t('site_url')}/static/images/forgot-password.png`} />

                <link rel="canonical" href={`${t('site_url')}/forgot-password`} />
            </Helmet>

            <div className="container login-container">
                <div className="row">
                    <div className="col-lg-12 mx-auto">
                        <div className="row justify-content-center">
                            <div className="col-md-6">
                                <div className="heading mb-1 mt-5">
                                    <h2 className="title">{t('forget_password')}</h2>
                                </div>
                                <form onSubmit={handleLogin}>
                                    <label htmlFor="login-email">
                                        {t('email_address')}
                                        (<span className="required">*</span>)
                                    </label>
                                   
                                   
                                    <input type="email" className="form-input form-wide" id="email_address" name="email_address" placeholder="Enter your registered email address" required value={email} onChange={(e) => setEmail(e.target.value)} />
                                   
                                    <br />
                                   
                                    {
                                        messageType === 1 &&
                                        <div className="alert alert-success">
                                        Your password reset link has been sent to your registered email address. Please follow the instructions in the email to reset your password.
                                        </div>
                                    }

                                    {
                                        messageType === 2 &&
                                        <div className="alert alert-danger">
                                        The email address is not registered with us. Please enter a valid registered email address.
                                        </div>
                                    }
                                    {
                                         loading === false? 
                                        <button type="submit" className="btn btn-dark btn-md w-100"> {t('reset_password')} </button>
                                        :<button type="button" disabled className="btn btn-dark btn-md w-100"> {t('reset_password')} </button>
                                    }
                                    <br />
                                   
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default ForgetPassword;