// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import arTranslation from './locales/ar.json'; // Import your translation files
// Add other language translations as needed

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslation }, 
      ar: { translation: arTranslation }, 
      // Add other languages here
    },
    lng: localStorage.getItem('lang')??'en', // Set the default language
    fallbackLng: 'en', // Fallback language if the translation is missing
    interpolation: {
      escapeValue: false, // React already escapes string
    },
  });

export default i18n;
