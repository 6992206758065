import React, { useContext, useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { ConfigContext } from "../../../Context/ConfigContext";
import { MagnifierContainer, MagnifierPreview, MagnifierZoom } from "@datobs/react-image-magnifiers";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
const OEPartImages = ({ PART_NUMBER }) => {
  const [medias, setMedias] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [loadingMedia, setLoadingMedia] = useState(false);
  const { gdcURL } = useContext(ConfigContext);
  const [images, setimages] = useState([]);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  useEffect(() => {
    GetPartMedias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PART_NUMBER]); // Fetch medias whenever ART_ID changes
  useEffect(() => {

  },[selectedImg])
  const GetPartMedias = () => {
    setLoadingMedia(true);
    fetch(`${gdcURL}/parts/GetOEPartMedias?part_number=${PART_NUMBER}`,

      {
        method: 'GET', // or 'POST', 'PUT', etc. depending on your request type
        headers: {
          'Content-Type': 'application/json', // Specify the content type if necessary
          
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("images", data.data)
        var iRows = [];
        for(var i = 0; i < data.data.length; i++){
          var row = data.data[i];
          if(row.media_type === 0)
          {
            var obj = {
              original: row.image_full_path,
              thumbnail: row.image_full_path,
            }
            iRows.push(obj)
          }
        }
        setimages(iRows);
        setLoadingMedia(false);
        
        
      })
      .catch((error) => {
        console.error("Error fetching media:", error);
        setLoadingMedia(false);
      });
  };

  const handleMediaClick = (index) => {
    setSelectedImg(index);
  };

  const getYouTubeThumbnail = (url) => {
    if (url && typeof url === 'string' && url.indexOf("youtube") !== -1) {
      const videoId = url.split("/").pop();
      const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
      return thumbnailUrl;
    } else {
      return "/assets/icons/360.png"; // Default or fallback image URL
    }
  };
  

  const thumbStyle = {
    border: "1px solid #CCC",
    padding: "10px",
    height: "100px",
    width: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer" // Add cursor pointer for clickable effect
  };
  const Thumbnails = () => {
    return <div style={{ marginTop: "10px" }}>
    {medias && (
      <Carousel
        swipeable={true}
        draggable={false}
        showDots={true}
        responsive={responsive}
        infinite={false}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
      >
        {medias.map((img, index) => (
          <div
            key={`img-part-${index}`}
            style={thumbStyle}
            onClick={() => handleMediaClick(index)}
          >
            <img
              alt="Ghayar"
              src={
                img.ART_MEDIA_TYPE === "JPEG" || img.ART_MEDIA_TYPE === "JPG" || img.ART_MEDIA_TYPE === "PNG"
                  ? `${gdcURL}/images/${img.ART_MEDIA_SOURCE}`
                  : getYouTubeThumbnail(img.ART_MEDIA_HIPPERLINK)
              }
              width={100}
            />
          </div>
        ))}
      </Carousel>
    )}
  </div>
  }
  const PictureFrame = () => {
    return <div style={{minHeight: '300px'}} data-toggle="modal" data-target="#galleryModalLong" >
    {
      medias && medias.map((thumb, index) => {
          if(index === selectedImg){
              
              if (thumb.ART_MEDIA_TYPE === "JPEG" || thumb.ART_MEDIA_TYPE === "JPG" || thumb.ART_MEDIA_TYPE === "PNG") {
                
                return <img key={`thumb-${index}`} alt="Ghayar" src={`${gdcURL}/images/${thumb.ART_MEDIA_SOURCE}`} style={{ height: "auto", width: "70%", margin: 'auto' }} />
                } else if (thumb.ART_MEDIA_TYPE === "URL") {
                  return <embed src={thumb.ART_MEDIA_HIPPERLINK.replaceAll('http:', 'https:')} type="application/pdf" style={{ height: "auto", width: "70%", margin: 'auto' }} />
                }
          }
      })
    }
    </div>
  }
  const galleryRef = useRef(null);

  const handleImageClick = () => {
    if (galleryRef.current) {
      galleryRef.current.fullScreen();
    }
  };
 
  return <ImageGallery ref={galleryRef} onClick={handleImageClick} originalHeight={400} items={images} showNav={false} showFullscreenButton={false} showPlayButton={false}/>;
  return (
    
    <div style={{marginTop: '45px'}}>
      {loadingMedia && <p>Loading media...</p>}
      <PictureFrame />
      <Thumbnails/>


      <div className="modal fade" id="galleryModalLong" tabIndex={-1} role="dialog" aria-labelledby="galleryModalLongTitle" aria-hidden="true" >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <PictureFrame />
              <Thumbnails/>
            </div>
            
          </div>
        </div>
      </div>

    </div>
  );
};

export default OEPartImages;
