import React,{useEffect} from 'react';
import { useParams, useNavigate , useLocation} from 'react-router-dom';
const withRouter = WrappedComponent => props => {
  var params = useParams();
  var navigate = useNavigate();
  var location = useLocation();
  useEffect(() => {
    // console.log("location: ", location);
  }, [location]);
  // Extract the query parameters
  var queryParams = new URLSearchParams(location.search);
  // Access a specific parameter by name  
  // const paramValue = queryParams.get('page');
  // console.log("withRouter", queryParams.get('part_brand'))
  return (
    <WrappedComponent
      {...props}
      params={params}
      navigate={navigate}
      queryParam={queryParams}
    />
  );
};
 
export default withRouter;