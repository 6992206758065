import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import withRouter from "../../Routes/withRouter";

class SelectedCarView extends Component{

    constructor(){
        super();
        this.state = {
            carData : null
        }
    }
    componentDidMount(){
        this.setState((prevState) => {
            return { carData: this.GetSelectedCarInformation() };
          }, () => {
            // console.log("Car Selected Data: ", this.state.carData);
          });
    }
    GetSelectedCarInformation = () => {
    
        const storedCars = JSON.parse(localStorage.getItem("cars"));
        if(storedCars){
    
          if(this.props.params.car_id){
            const selectedCar = storedCars.filter((car) => car.car_id === Number(this.props.params.car_id));
          console.log("selectedCar From BC 1", selectedCar);
          return selectedCar[0];
        }else if(storedCars.length > 0){
          const selectedCar = storedCars.filter((car) => car.selected === 1);
          console.log("selectedCar From BC 2", selectedCar);
          return selectedCar[0];
        }else{
          return false;
        }
        }else{
          return false;
        }
      }
    render(){
        
        const carData = this.GetSelectedCarInformation();
        return (
        <>
            {carData &&
                <div className="container">
                 <div className="selected-car-for-mob">
                   <div className="pl-2">
                     <div className="text-center">
                       <h1 className="text-dark h5">
                         {carData
                           ? `${carData.car_year} ${carData.car_brand} ${carData.car_model} ${carData.car_engine}`
                           : "Loading..."}
                       </h1>
                     </div>
                     <div className="d-flex justify-content-center">
                       <button className="btn btn-outline-primary btn-ellipse btn-sm"
                       data-toggle="modal" data-target=".bg-modal-for-car-select"
                       >
                         {('ChangeCar')}
                       </button>
                       <NavLink
                            to={`/Catalog/${carData.car_id}/${carData.car_brand.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')}/${carData.car_year}/${carData.car_model.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')}/${carData.car_engine.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '_')}`}
  
                          className="btn btn-sm"
                          style={{
                            fontSize: "10px",
                            textDecoration: "none",
                            backgroundColor: "transparent",
                          }}
                        >
                          <span className="mr-3" style={{ color : "grey" }}>{('BrowseCatalog')}</span>
                        </NavLink>
                     </div>
                   </div>
                 </div>
               </div>
            } 
</>
        )
        
    }
}
export default withRouter(SelectedCarView)