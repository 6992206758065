import React, { useState } from 'react';


const WhyBuyFromBrand = ({brand, parts, models}) => {
    const [title, setTitle] = useState({ color: '#132530', fontFamily: 'Montserrat, Arial', fontWeight:'501', fontSize: '16px', fontWeight: '500', lineHeight: '26px' });
    const [description, setDescription] = useState({ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '13px', fontWeight: '500', lineHeight: '20px' });
    const [imgStyle, setimgStyle] = useState({width: '90%', float: 'right'});
    return (
        <section className="section wrap">
            <h2 style={{ color: '#132530', fontFamily: 'Montserrat, Arial, sans-serif', fontSize: '22px', fontWeight: '500', lineHeight: '24px' }}>
                Why should you buy {brand} car products?
            </h2>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/popular-icon.svg' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            Popular Aftermarket Manufacturer
                            </span>
                            <p style={description}>{brand} products are similar in quality to original parts and are of high demand among the customers of our online shop</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/document-icon.svg' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            Wide choice of automotive products
                            </span>
                            <p style={description}>
                            {brand} products are similar in quality to original parts and are of high demand among the customers of our online shop
                            </p>
                        </div>
                    </div>
                </div>


                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/star-black.svg' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            Ranked by customers: 4.3/5
                            </span>
                            <p style={description}>
                            An excellent price/quality ratio of {brand} products allows you to considerably save while doing maintenance and repair of your car
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/security-icon.svg' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            Wide coverage
                            </span>
                            <p style={description}>
                            In our e-catalogue, you can find inexpensive {brand} auto parts for 76933 car models, which will be cross-compatible and similar to original parts
                            </p>
                        </div>
                    </div>
                </div>

                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/catalog-icon.svg' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            Safe order within 200 days
                            </span>
                            <p style={description}>
                            Clear your doubts about online shopping! Use the Safe Order extra option when ordering {brand} replacement parts, and you will be able to return your order within 200 days since the purchase date
                            </p>
                        </div>
                    </div>
                </div>


                <div className='col-md-6 mt-2'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <img style={imgStyle} src='/assets/icons/accessories-price-icon.svg' />
                        </div>
                        <div className='col-sm-10'>
                            <span style={title}>
                            Best price guarantee
                            </span>
                            <p style={description}>
                            Want to save? Looking for the best prices for {brand} products? Then view our offers and see for yourself that our pricing for all parts and accessories of this brand is excellent.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyBuyFromBrand;
