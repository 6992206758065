import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import QRCode from 'qrcode';
import { useEffect, useState } from "react";

const InvoicePDF = ({ order_uid, details, items, address }) => {
    const [qrCodeURL, setQrCodeURL] = useState('');
    useEffect(() => {
        QRCode.toDataURL('https://ghayar.com')
        .then(url => {
            setQrCodeURL(url);
            console.log(url)
        })
        .catch(err => {
            console.error(err)
        })

    }, [])
  const generatePDF = () => {
    
    return new Promise((resolve, reject) => {
      const doc = new jsPDF();
      
      // Log the QR code URL to verify it's generated
      console.log('QR Code URL:', qrCodeURL);

      // Add images to PDF
      doc.addImage('/assets/images/Logo/car7_transparent_powered_by.png', 'PNG', 40, 20, 60, 20);
      doc.addImage(qrCodeURL, 'PNG', 140, 60, 30, 30);
        
        // Add title
        doc.setFontSize(20);
        doc.text('Invoice', 105, 25, null, null, 'center');
        
        // Add order details
        doc.setFontSize(12);
        const orderDate = new Date(details.created_at);
        const formattedDate = isNaN(orderDate)
          ? new Intl.DateTimeFormat('en-GB').format(new Date())
          : new Intl.DateTimeFormat('en-GB').format(orderDate);
        doc.text(`Order Number: ${order_uid}`, 20, 50);
        doc.text(`Date: ${formattedDate}`, 20, 60);
        
        // Add customer details
        doc.text('Bill To:', 20, 75);
        doc.setFontSize(10);
        doc.text(`${address.first_name} ${address.last_name}`, 20, 82);
        doc.text(`${address.complete_address}`, 20, 89);
        doc.text(`${address.city}, ${address.country}`, 20, 96);
        doc.text(`${address.mobile_number_1}`, 20, 103);
        doc.text(`${address.contact_email}`, 20, 110);
        
        // Add items table
        const tableColumn = ["Item", "Price", "Quantity", "Total"];
        const tableRows = items.map(item => [
          item.item_title,
          item.item_display_price,
          item.item_qty,
          (item.item_qty * item.item_price).toFixed(2)
        ]);
        
        doc.autoTable({
          startY: 120,
          head: [tableColumn],
          body: tableRows,
          headStyles: { fillColor: [255, 0, 0] } // Set header color to red
        });
        
        // Add total
        const finalY = doc.lastAutoTable.finalY || 120;
        doc.text(`Subtotal: ${details.sub_total_display}`, 140, finalY + 20);
        doc.text(`Shipping: ${details.display_shipping_cost}`, 140, finalY + 27);
        if(details.cod_applicable === 1){
          doc.text(`COD: ${details.display_cod_charges}`, 140, finalY + 34);
        }
        doc.text(`Discount: ${details.discounted_amount}`, 140, finalY + 41);
        doc.text(`Grand Total: ${details.grand_total_display}`, 140, finalY + 48);
        
        // Footer
        doc.setFontSize(10);
        doc.text('Thank you for your business!', 105, 280, null, null, 'center');
        
        resolve(doc);
      });
    
  };

  const downloadPDF = async () => {
    try {
      const doc = await generatePDF();
      doc.save(`invoice_${order_uid}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };

  const printPDF = async () => {
    try {
      const doc = await generatePDF();
      doc.autoPrint();
      window.open(doc.output('bloburl'), '_blank');
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };

  const getEmailPDF = async () => {
    try {
      const doc = await generatePDF();
      return doc.output('datauristring');
    } catch (error) {
      console.error('Error generating PDF:', error);
      throw error;
    }
  };

  return { downloadPDF, printPDF, getEmailPDF };
};

export default InvoicePDF;
