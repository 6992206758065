import React, { useContext, useState, useEffect, useRef } from "react";
import "../sidebar.css";

import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../Context/ConfigContext";
import slugify from "slugify";

const Sidebar = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const dir = localStorage.getItem("dir");
    const { categories, treeCategories, CarStatus, gdcURL, setCategories, CartItemsQty, CartList, isLoggedIn, lang, setLang, sideBarStatus, setSideBarStatus } = useContext(ConfigContext);
    const [hoveredItem, setHoveredItem] = useState(null);
  
    const layerFirst = {
        top: 0,
        width: '300px',
        height: '100vh',
        backgroundColor: '#f9fafb',
        position: 'fixed',
        zIndex: 100000,
        overlay: 'hidden',
        display: sideBarStatus?'block': 'none'
    };
    // useEffect(() => {
    //     alert(sidebar)
    // }, [sidebar])
    const layerSecond = {
        top: 0,
        left: '300px',
        width: '300px',
        height: '100vh',
        backgroundColor: '#f9fafb',
        position: 'absolute',
        zIndex: 1000,
        transition: 'transform 0.3s ease-in-out',
        transform: 'translateX(100%)',
        visibility: 'hidden'
    };
  
    const layerSecondVisible = {
        ...layerSecond,
        transform: 'translateX(0)',
        visibility: 'visible',
        borderLeft: '1px solid rgb(232, 233, 233)'
    };

    const liStyle = {
      paddingTop: '10px',
      paddingBottom: '10px',
      paddingLeft: '10px',
      borderBottom: '1px solid rgb(232, 233, 233)'
    }
    
    
    const divRef = useRef(null);

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      // Clicked outside the div, handle the logic here
      if(setSideBarStatus){
        setHoveredItem(null); setSideBarStatus(false);
      }
      
    //   alert("clicked")
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
    
    return (
        <div ref={divRef} style={layerFirst}>
            <i onClick={() => {setHoveredItem(null); setSideBarStatus(false);}} className="fas fa-times" style={{position: 'absolute', right: '-18px', zIndex: '10', backgroundColor: '#FFF', padding: '4px 4px', zIndex: 10, cursor: 'pointer'}}></i>
            <div style={{overflow: 'auto', height: '100vh'}}>
            
            <ul style={{zIndex: 1000000, }}>
                {treeCategories && treeCategories.length > 0 && treeCategories.map((category, index) => (
                    <li style={liStyle} onClick={() => setHoveredItem(category.STR_ID)}  key={`${category.STR_ID}-${index}`} >
                        <NavLink to="#" >{category.STR_NODE_NAME}</NavLink>
                        {
                        category.children && category.children.length > 0 && <i className="fa fa-chevron-right mr-4" style={{float: 'right'}}></i>}
                        {
                        category.children && category.children.length > 0 && <div style={hoveredItem === category.STR_ID ? layerSecondVisible : layerSecond}>
                            <i onClick={() => {setHoveredItem(null); setSideBarStatus(false);}} className="fas fa-times" style={{position: 'absolute', right: '-18px', zIndex: '10', backgroundColor: '#FFF', padding: '4px 4px', cursor: 'pointer'}}></i>
                            <ul>
                                {category.children.map((child, index) => (
                                    <li style={liStyle} key={`${child.STR_ID}-${index}`}>
                                        <NavLink onClick={() => {setSideBarStatus(false); setHoveredItem(null)}} to={`/Spare-Parts/${slugify(child.STR_NODE_NAME)}-${child.STR_ID}`}>{child.STR_NODE_NAME}</NavLink>
                                    </li>
                                ))}
                            </ul>
                          
                        </div>
                        }
                        
                    </li>
                ))}
                
            </ul>
            </div>
        </div>
    );
};

export default Sidebar;
