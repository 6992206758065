const SocialMediaFooter = () => {
    return <div
    className="block block-posts-carousel block-posts-carousel--layout--list"
    data-layout="list"
    style={{ marginTop: 100 }}
  >
    <div className="container-fluid" style={{ backgroundColor: "#0e1f2a" }}>
      <div className="container">
        <div className="row">
          <div
            className="col-md-4"
            style={{
              height: 200,
              
              textAlign: "center"
            }}
          >
            <img
              src="https://ghayar.ae/assets/dist/images/screenshotofapp.png"
              alt=""
              style={{ marginTop: "-100px", height: 300 }}
            />
          </div>
          <div
            className="col-md-3"
            style={{
              textAlign: "center",
              fontFamily: '"Inter"',
              fontWeight: 200,
              height: 200,
              
              paddingTop: 23
            }}
          >
            <img
              src="https://ghayar.ae/assets/dist/images/appqrycode.png"
              alt=""
              style={{ width: 110, margin:'auto' }}
            />
            <br />
            <span style={{ color: "#FFF" }}>
              SCAN THE QR CODE TO DOWNLOAD THE APP
            </span>
          </div>
          <div
            className="col-md-4"
            style={{
              textAlign: "center",
              fontFamily: '"Inter"',
              fontWeight: 200,
              height: 200
            }}
          >
            <div style={{ color: "#FFF", marginTop: 80 }}>
              Purchasing products via ghayar app is always a quick and great
              experience
            </div>
          </div>
          <div className="col-md-1" style={{ textAlign: "center" }}>
            <div style={{ height: 53 }} />
            <table style={{ width: 130, margin: "auto" }} cellSpacing={20}>
              <tbody>
                <tr>
                  <td>
                    <a href="https://play.google.com/store/apps/details?id=com.ghayar">
                      <img
                        src="https://ghayar.ae/assets/dist/images/playstore.png"
                        alt=""
                        style={{ width: 130 }}
                      />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a href="https://apps.apple.com/in/app/ghayar-spare-parts/id1571024450">
                      {" "}
                      <img
                        src="https://ghayar.ae/assets/dist/images/appstore.png"
                        alt=""
                        style={{ width: 130 }}
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ height: 23 }} />
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid" style={{ backgroundColor: "#132531" }}>
      <div className="container" >
        <div
          className="row"
          style={{
            textAlign: "left",
            color: "#CECCCC",
            fontFamily: '"Inter"',
            fontWeight: 200,
          }}
        >
          <div
            className="col-sm-4"
            style={{  padding: 20 }}
          >
            <table cellPadding={5}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://ghayar.ae/assets/dist/images/instagramupdates.png"
                      style={{ width: "100%" }} 
                      alt=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ color: "#CCC" }}>
                      Follow the latest trends and enjoy updates,useful tips, and
                      posts about cars
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.instagram.com/ghayar.ae/"
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        color: "#FFF"
                      }}
                    >
                      SEE MORE ON INSTAGRAM <i className="fa fa-play" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-sm-4"
            style={{  padding: 20 }}
          >
            <table cellPadding={5}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://ghayar.ae/assets/dist/images/youtubeupdates.png"
                      style={{ width: "100%" }} 
                      alt=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ color: "#CCC" }}>
                      Follow the latest trends and enjoy updates,useful tips, and
                      posts about cars
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.youtube.com/@ghayarautospareparts8352"
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        color: "#FFF"
                      }}
                    >
                      SEE MORE ON YOUTUBE <i className="fa fa-play" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-sm-4"
            style={{  padding: 20 }}
          >
            <table cellPadding={5}>
              <tbody>
                <tr>
                  <td>
                    <img
                      src="https://ghayar.ae/assets/dist/images/facebooksupdates.jpg"
                      style={{ width: "100%" }} 
                      alt=""
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p style={{ color: "#CCC" }}>
                      Follow the latest trends and enjoy updates,useful tips, and
                      posts about cars
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href="https://www.facebook.com/ghayar.ae"
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        color: "#FFF"
                      }}
                    >
                      SEE MORE ON FACEBOOK <i className="fa fa-play" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>  
}

export default SocialMediaFooter;