import React, {Component} from "react";

import { ConfigContext } from "../../../Context/ConfigContext";
import PartsCard from "../../Cards/PartsCard";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
class FeaturedProducts extends Component {
  static contextType = ConfigContext;
  constructor(){
    super();
    this.state = {
      featuredCSS: 'products-slider custom-products owl-carousel owl-theme nav-outer show-nav-hover nav-image-center'
    }
  }
  componentDidMount(){
    this.setState({featuredCSS: 'products-slider custom-products owl-carousel owl-theme nav-outer show-nav-hover nav-image-center'});
  }
    render(){
        return(
            <section className="featured-products-section">
              <div className="container">
                <h2 className="section-title heading-border ls-20 border-0">{("FeaturedProducts")}</h2>
                <OwlCarousel className='owl-theme' items={5} loop margin={10} dots={false} responsive={false} nav>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                </OwlCarousel>
                {/* End .featured-proucts */}
              </div>
            </section>
        )
    }
}
export default FeaturedProducts