import React, { useContext, useState } from "react";
import axios from "axios";
import { ConfigContext } from "../Context/ConfigContext";



const NotifyMeForm = ({style}) => {
    const {apiURL} = useContext(ConfigContext);
  // State to hold form values
  const [formData, setFormData] = useState({
    email: "",
    vin: "",
    partNumber: ""
  });
  const [sending, setSending] = useState(false);

  // State for success and error messages
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  // Handle change for input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setSending(true);
    try {
      // Clear any previous messages
      setMessage(null);
      setError(null);

      // Axios POST request to submit form data
      var response = axios.post(`${apiURL}/Parts/NotifyMe`, formData);

      // If the request is successful, reset the form and show success message
      setFormData({ email: "", vin: "", partNumber: "" });
      setMessage("Notification request submitted successfully!");
      setSending(false);

    } catch (error) {
      // Show error message on failure
      setError("Failed to submit notification request. Please try again later.");
      setSending(false);
    }
  };

  return (
    <div className="container mt-4" style={style}>
      <h3>Notify Me</h3>

      {/* Success or Error Messages */}
      {message && <div className="alert alert-success" role="alert">{message}</div>}
      {error && <div className="alert alert-danger" role="alert">{error}</div>}

      <form onSubmit={handleSubmit} >
        {/* Email Field */}
        <div className="form-group mb-3">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            placeholder="Enter Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        {/* VIN and Part Number Fields */}
        <div className="row">
          {/* VIN Field */}
          <div className="col-md-6 mb-3">
            <label htmlFor="vin">VIN</label>
            <input
              type="text"
              className="form-control"
              id="vin"
              name="vin"
              placeholder="Enter Your VIN/Chassis Number"
              value={formData.vin}
              onChange={handleChange}
              required
            />
          </div>

          {/* Part Number Field */}
          <div className="col-md-6 mb-3">
            <label htmlFor="partNumber">Part Number</label>
            <input
              type="text"
              className="form-control"
              id="partNumber"
              name="partNumber"
              placeholder="Enter Part / Article Number"
              value={formData.partNumber}
              onChange={handleChange}
              required
            />
          </div>
        </div>

        {/* Submit Button */}
        {
            sending === false?
            <button type="submit" className="btn btn-primary">Notify Me</button>
            :<button disabled type="button" className="btn btn-primary">Please wait</button>
        }
        
      </form>
    </div>
  );
};

export default NotifyMeForm;
