import React, {Component} from "react";
import { ConfigContext } from "../../../Context/ConfigContext";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PartsCard from "../../Cards/PartsCard";
class NewArrivals extends Component{
  static contextType = ConfigContext;
    render(){

    return (
      <section className="new-products-section">
              <div className="container">
                <h2 className="section-title heading-border ls-20 border-0">{("New Arrivals")}</h2>
                <OwlCarousel className='owl-theme' items={6} loop margin={10} dots={false} responsive={false} nav>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                  <PartsCard title={`Filter Oil`} price={`AED49.00`} mrpPrice={`AED60.00`} action={true}/>
                </OwlCarousel>
                {/* End .featured-proucts */}
              </div>
            </section>
    
    )
  }
}

export default NewArrivals