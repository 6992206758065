import React, { useEffect, useState, useContext } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { NavLink } from "react-router-dom";
import AccountSiderBar from "./AccountSideBar";
import { Spinner } from "../../Components/Shimmers";
import { useTranslation } from 'react-i18next';

const Downloads = () => {
    const [orders, setOrders] = useState([]);
    const [ordersLoading, setOrdersLoading] = useState(false);
    const { apiURL, token } = useContext(ConfigContext);
    const { t } = useTranslation();
    const dir = localStorage.getItem('dir');

    useEffect(() => {
        setOrdersLoading(true);
        getOrders();
    }, []);

    const getOrders = async () => {
        try {
            const customer_id = localStorage.getItem("customer_id");
            const headers = {
                token: token,
                Accept: "application/json",
                "Content-Type": "application/json",
            };

            const response = await axios.get(
                `${apiURL}/Account/GetMyOrders?customer_id=${customer_id}`,
                { headers }
            );

            const data = response.data;
            setOrdersLoading(false);
            if (data.success) {
                setOrders(data.data);
            } else {
                console.log("Error While Fetching Customer Addresses", data.message);
            }
        } catch (error) {
            console.log("Oops Something Went Wrong", error);
            setOrdersLoading(false);
        }
    };

    return (
        <main>
            <div className="page-header">
                <div className="container d-flex flex-column align-items-center">
                    <nav aria-label="breadcrumb" className="breadcrumb-nav">
                        <div className="container">
                            <ol className="breadcrumb" dir={dir}>
                                <li className="breadcrumb-item">
                                    <NavLink to={'/'}>{t("home")}</NavLink>
                                </li>
                                <li className="breadcrumb-item">
                                    <NavLink to={`/MyAccount`}>{t("my_account")}</NavLink>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    {t("downloads")}
                                </li>
                            </ol>
                        </div>
                    </nav>
                    <h1>{t("downloads")}</h1>
                </div>
            </div>
            <div className="container account-container custom-account-container">
                <div className="row">
                    <AccountSiderBar />
                    <div className="col-lg-9 order-lg-last order-1 tab-content">
                        <div className="tab-pane fade show active" id="address" role="tabpanel">
                            <div className="order-content">
                                <h3 className={`account-sub-title d-none d-md-block ${dir==='rtl'?'text-right': ''}`} dir={dir}>
                                    <i className="sicon-social-dropbox align-middle mr-3" />
                                    {t("downloads")}
                                </h3>
                                <div className="order-table-container text-center" >
                                    <table className="table table-order text-left">
                                        <thead>
                                            <tr>
                                                <th className="order-id">{t("order")}</th>
                                                <th className="order-date">{t("date")}</th>
                                                <th className="order-status">{t("status")}</th>
                                                <th className="order-price">{t("total")}</th>
                                                <th className="order-action">{t("actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                ordersLoading === true? <tr><td colSpan={15} className="text-center"><center><Spinner/></center></td></tr>:
                                                orders.length>0?orders.map((order)=>{
                                                    return <tr>
                                                        <td className="order-id"><NavLink to={`/MyAccount/Orders/${order.order_uid}`} className={`nav-link`}>{order.order_uid}</NavLink></td>
                                                        <td className="order-date">{order.order_datetime}</td>
                                                        <td className="order-status">{("STATUS")}</td>
                                                        <td className="order-price">{order.grand_total}</td>
                                                        <td className="order-action">
                                                            <NavLink to={`/MyAccount/Orders/${order.order_uid}`}> <button className="btn btn-primary btn-sm" style={{padding: '0.2em 0.5em',fontSize: '1.1rem'}}><i className="fa fa-download" ></i> {t("download_invoice")}</button></NavLink>
                                                        </td>
                                                    </tr>
                                                }):
                                                <tr>
                                                    <td className="text-center p-0" colSpan={5}>
                                                        <p className="mb-5 mt-5">
                                                            {t("no_order_yet_made")}
                                                        </p>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <hr className="mt-0 mb-3 pb-2" />
                                    <NavLink to={`/`} className="btn btn-primary">
                                        {t("go_shop")}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default Downloads;
